import { Component, DestroyRef, inject, OnInit } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { take } from 'rxjs/operators';

import { ReportService } from 'src/app/core/api/report.service';

import { SiteService } from '../../../core/api/site.service';
import { FormatHelperService } from '../../../core/helper/format-helper.service';
import { SiteHelperService } from '../../../core/helper/site.service';
import * as fromApp from '../../../store';
import { selectCurrentUser } from '../../../store/auth/auth.selectors';
import { setActiveSiteFailure } from '../../../store/site/site.actions';
import { DISTURBANCE_CAUSES, IMPACT_PERCENTAGES } from '../../constants/disturbance_report.constants';
import { LegalConstants } from '../../constants/legal.constants';
import { Site } from '../../models';
import { SpinnerService } from '../../spinner/spinner.service';

@Component({
  selector: 'fc-disturbance-report',
  templateUrl: './disturbance-report.component.html',
  styleUrls: ['./disturbance-report.component.scss'],
})
export class DisturbanceReportComponent implements OnInit {
  destroyRef = inject(DestroyRef);

  id: number = null;
  reportId: number = null;
  site: Site = null;
  statuses = [];
  parcelCommaSeparatedList: string = null;
  reportForm: UntypedFormGroup;
  isAdmin: boolean = false;

  disturbanceCauses = DISTURBANCE_CAUSES;
  impactPercentages = IMPACT_PERCENTAGES;

  constructor(
    public dialog: MatDialog,
    readonly store: Store<fromApp.AppState>,
    readonly router: Router,
    private route: ActivatedRoute,
    private formBuilder: UntypedFormBuilder,
    readonly spinnerService: SpinnerService,
    readonly reportService: ReportService,
    readonly siteHelper: SiteHelperService,
    readonly formatHelper: FormatHelperService,
    readonly siteService: SiteService
  ) {
    this.createForm();
  }

  ngOnInit() {
    this.statuses = this.siteHelper.siteStatusList;
    this.id = +this.route.snapshot.params.id;
    if (this.route.snapshot.params.reportId) {
      this.reportId = +this.route.snapshot.params.reportId;
    }

    this.siteService.getSite(this.id).subscribe(
      site => {
        this.site = site;
        this.parcelCommaSeparatedList = this.site.versions[0].parcels.map(p => p.parcelNumber).join(', ');
      },
      error => this.store.dispatch(setActiveSiteFailure({ error }))
    );

    this.store
      .select(selectCurrentUser)
      .pipe(take(1), takeUntilDestroyed(this.destroyRef))
      .subscribe(currentUser => {
        this.isAdmin = currentUser.isAdmin;
      });

    if (this.reportId) {
      this.reportService.getDisturbanceReport(this.reportId).subscribe(disturbanceReport => {
        if (disturbanceReport) {
          this.reportForm.controls.disturbanceType.setValue(disturbanceReport.disturbanceType);
          this.reportForm.controls.cause.setValue(disturbanceReport.cause);
          this.reportForm.controls.impactPercentage.setValue(disturbanceReport.impactPercentage);
          this.reportForm.controls.description.setValue(disturbanceReport.description);
        }

        if (this.readOnly) {
          this.reportForm.disable();
        }
      });
    }
  }

  createForm(): void {
    this.reportForm = this.formBuilder.group({
      disturbanceType: ['', Validators.required],
      cause: ['', Validators.required],
      impactPercentage: ['', Validators.required],
      description: ['', Validators.required],
      legalAck: [false, Validators.requiredTrue],
    });
  }

  getStandCount(): number {
    let standCount = 0;
    if (this.site?.versions.length > 0 && this.site.versions[0].stands) {
      standCount = this.site.versions[0].stands.length;
    }
    return standCount;
  }

  getOwnerName() {
    if (this.site?.account?.user) {
      return this.site.account.user.getDisplayName({});
    }
    return '';
  }

  isOwnerVerified(): boolean {
    if (this.site?.account?.user) {
      return this.site.account.user.isVerified;
    }
    return false;
  }

  getArea(): number {
    if (this.site?.area) {
      return Math.round(this.site.area);
    }
    return 0;
  }

  submitReport(): void {
    this.spinnerService.show('app-spinner');
    const newReport = {
      disturbance_type: this.reportForm.value.disturbanceType,
      cause: this.reportForm.value.cause,
      impact_percentage: this.reportForm.value.impactPercentage,
      description: this.reportForm.controls.description.value,
    };
    this.reportService.submitDisturbanceReport(this.site.id, newReport).subscribe(
      () => {
        this.reportForm.markAsPristine();
        if (this.isAdmin) {
          this.router.navigate(['admin/dashboard']);
        } else {
          this.router.navigate(['account/dashboard']);
        }
      },
      error => {
        throw new Error(error.message);
      },
      () => this.spinnerService.hide('app-spinner')
    );
  }

  get projectedNext12MonthRevenue() {
    return this.formatHelper.formatMinMaxValuation(this.site.getAverageAnnualRevenue());
  }

  get readOnly(): boolean {
    return this.isAdmin || this.reportId != null;
  }

  get isFormDirty(): boolean {
    return this.reportForm.dirty;
  }

  protected readonly LegalConstants = LegalConstants;
}
