import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';

import { ProgramService } from '../../../core/api/program.service';
import { Program } from '../../models';

@Component({
  selector: 'fc-program-list',
  templateUrl: './program-list.component.html',
  styleUrls: ['./program-list.component.scss'],
})
export class ProgramListComponent implements OnInit {
  @ViewChild('programInput') programInput: ElementRef<HTMLInputElement>;
  allPrograms: Program[] = null;
  @Input() selectedPrograms: Program[] = [];
  filteredPrograms: Program[] = [];
  separatorKeysCodes: number[] = [ENTER, COMMA];
  @Input() regionId: number = null;
  @Output() selectedProgramsChange = new EventEmitter<Program[]>();
  @Input() readonly = false;

  constructor(readonly programService: ProgramService) {}
  ngOnInit() {
    this.programService.getAllPrograms(this.regionId).subscribe(programs => {
      this.allPrograms = programs.filter(p => p.isActive);
      this.resetFilterValue();
    });
  }

  removeProgram(program: Program): void {
    this.selectedPrograms = this.selectedPrograms.filter(p => p.name != program.name);
    this.resetFilterValue();
    this.selectedProgramsChange.emit(this.selectedPrograms);
  }

  filterPrograms($event: KeyboardEvent) {
    setTimeout(() => {
      const value = ($event.target as HTMLInputElement).value;
      const filterValue = value.toLowerCase().trim();

      if (!filterValue || filterValue === '') {
        this.resetFilterValue();
      } else {
        this.filteredPrograms = this.allPrograms
          .filter(p => !this.selectedPrograms.find(pr => pr.name === p.name))
          .filter(program => program.name.toLowerCase().includes(filterValue));
        if (!this.allPrograms.find(p => p.name.toLowerCase() === filterValue)) {
          this.filteredPrograms.push(new Program({ name: value }));
        }
      }
    }, 500);
  }

  resetFilterValue() {
    this.filteredPrograms = this.allPrograms.filter(p => !this.selectedPrograms.find(pr => pr.name === p.name));
  }

  selectProgram(event: MatAutocompleteSelectedEvent) {
    const selectedProgram = event.option.value as Program;
    if (!this.selectedPrograms.find(p => p.name === selectedProgram.name)) {
      this.selectedPrograms.push(selectedProgram);
    }
    this.resetFilterValue();

    this.programInput.nativeElement.value = '';
    this.selectedProgramsChange.emit(this.selectedPrograms);
  }
}
