import { Component, DestroyRef, inject, OnInit } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { MatDialog } from '@angular/material/dialog';
import { FloatLabelType } from '@angular/material/form-field';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { isEmpty } from 'lodash';
import { filter } from 'rxjs/operators';

import { ErrorHelperService } from 'src/app/core/helper/error-helper.service';

import { environment } from '../../../../environments/environment';
import { BuyerService } from '../../../core/api/buyer.service';
import { PaymentService } from '../../../core/api/payment.service';
import { UserService } from '../../../core/api/user.service';
import { AuthMode } from '../../../feature/auth/auth.component';
import * as fromApp from '../../../store';
import { selectAppConfigState } from '../../../store/app-config/app-config.selectors';
import { logout, setCurrentUser } from '../../../store/auth/auth.actions';
import { selectCurrentUser } from '../../../store/auth/auth.selectors';
import {
  getAdminNotifications,
  resetStripeAccountRequiresAction,
} from '../../../store/notification/notification.actions';
import * as fromNotification from '../../../store/notification/notification.reducer';
import { selectNotificationState } from '../../../store/notification/notification.selectors';
import { getUserAvailableSites } from '../../../store/site/site.actions';
import { selectAvailableSites } from '../../../store/site/site.selectors';
import { createDelegate, removeDelegate } from '../../../store/user/user.actions';
import { selectUserState } from '../../../store/user/user.selectors';
import { LegalConstants } from '../../constants/legal.constants';
import { RegexConstants } from '../../constants/regex.constants';
import { states } from '../../constants/states.contants';
import { ChangeEmailDialogComponent } from '../../dialogs/dialog-change-email/dialog-change-email.component';
import { DialogManualIDComponent } from '../../dialogs/dialog-manual-id/dialog-manual-id.component';
import { DialogYesNoComponent } from '../../dialogs/dialog-yes-no/dialog-yes-no.component';
import { OtpDialogComponent } from '../../dialogs/otp-dialog/otp-dialog.component';
import { Buyer, Site, StripeAccount, User } from '../../models';
import { SpinnerService } from '../../spinner/spinner.service';

@Component({
  selector: 'fc-profile-page',
  templateUrl: './profile-page.component.html',
  styleUrls: ['./profile-page.component.scss'],
})
export class ProfilePageComponent implements OnInit {
  activeSites: Site[] = null;
  addingDelegate: string;
  authModeEnum = AuthMode;
  buyers: Buyer[] = [];
  city: string;
  company: string;
  configuredStripeAccounts: Array<StripeAccount> = [];
  createDelegateForm: UntypedFormGroup;
  createdSites: Site[] = null;
  currentUser: User;
  delegateEmailErrorMessage = '';
  delegateUser: User;
  editingEmail = false;
  editingProfile = false;
  email: string;
  environment = environment;
  error: string;
  errorMessage: string = null;
  hasActiveSites: boolean = false;
  helpEmail: string = '';
  helpPhone: string = '';
  id: number = null;
  isAdmin = false;
  isLimitedAdmin = false;
  isSuperAdmin = false;
  notificationState: fromNotification.NotificationState;
  phone: string;
  profileEmailErrorMessage = '';
  profileUser: User;
  resetMessage: string;
  showAddDelegate = false;
  sitesLoaded: boolean = false;
  state: string;
  stateList = states;
  streetAddress: string;
  stripeCode: string = null;
  stripeDisclaimer = LegalConstants.STRIPE_DISCLAIMER;
  stripeToken: string = null;
  title: string;
  unconfiguredStripeAccounts: Array<StripeAccount> = [];
  updateEmailForm: UntypedFormGroup;
  updateProfileForm: UntypedFormGroup;
  userFullName: string;
  userType: string = '';
  zip: string;

  destroyRef = inject(DestroyRef);

  getOpenAssetURL = BuyerService.getOpenAssetURL;

  constructor(
    readonly store: Store<fromApp.AppState>,
    readonly route: ActivatedRoute,
    readonly paymentService: PaymentService,
    readonly router: Router,
    readonly userService: UserService,
    readonly spinnerService: SpinnerService,
    private formBuilder: UntypedFormBuilder,
    private dialog: MatDialog,
    readonly errorHelperService: ErrorHelperService
  ) {
    this.createForms();
  }

  ngOnInit() {
    this.id = +this.route.snapshot.params.id;

    if (this.id) {
      this.userService.getUser(this.id).subscribe(user => {
        this.setProfileUser(user);
      });
    }

    this.store
      .select(selectCurrentUser)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(user => {
        if (!this.currentUser && user) {
          this.currentUser = user;
        }
        if (!this.id) {
          this.setProfileUser(user);
          this.userType = this.getUserType(this.profileUser);
        }
        if (user) {
          this.userType = this.getUserType(user);
          this.isAdmin = user.isAdmin;
          this.isSuperAdmin = user.isSuperAdmin;
          this.isLimitedAdmin = user.isLimitedAdmin;
          this.store
            .select(selectAvailableSites)
            .pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe(availableSites => {
              this.sitesLoaded = availableSites != null;
              this.createdSites = availableSites || [];
              this.activeSites = availableSites ? availableSites.filter(a => a.activationDate !== null) : null;
              this.hasActiveSites = this.activeSites && this.activeSites.length > 0;
              if (this.hasActiveSites) {
                this.activeSites.forEach(site => {
                  this.buyers.push(site.cohort.buyer);
                });
              }
              if (this.profileUser) {
                this.unconfiguredStripeAccounts = this.profileUser.stripeAccounts.filter(sa =>
                  isEmpty(sa.stripeUserIdentifier)
                );
                this.configuredStripeAccounts = this.profileUser.stripeAccounts.filter(
                  sa => !isEmpty(sa.stripeUserIdentifier)
                );
              }
            });
        }
      });

    this.store
      .select(selectNotificationState)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(notificationState => {
        this.notificationState = notificationState;
      });

    this.store
      .select(selectUserState)
      .pipe(
        filter(() => !isEmpty(this.addingDelegate)),
        takeUntilDestroyed(this.destroyRef)
      )
      .subscribe(state => {
        this.addingDelegate = null;
        if (!isEmpty(state.error)) {
          this.error = state.error;
          console.log(this.error);
        } else {
          this.error = null;
          this.showAddDelegate = false;
        }
      });

    // Remove query params so you don't authenticate your stripe account twice which invalidates the account
    this.router.navigate([], {
      queryParams: {
        code: null,
        state: null,
      },
      queryParamsHandling: 'merge',
    });

    this.stripeCode = this.route.snapshot.queryParamMap.get('code');
    this.stripeToken = this.route.snapshot.queryParamMap.get('state');

    this.store
      .select(selectAppConfigState)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(appConfig => {
        this.helpEmail = appConfig.config['fc_help_email'];
        this.helpPhone = appConfig.config['fc_help_phone'];
      });

    // Check if stripe code was returned and token is valid
    if (this.stripeCode) {
      const account = this.profileUser.getAccountFromToken(this.stripeToken);
      if (account) {
        this.paymentService.authenticateAccount(this.stripeCode, account.buyerId).subscribe(user => {
          this.store.dispatch(setCurrentUser({ data: user }));
        });
      }
    }

    this.createDelegateForm
      .get('delegateEmail')
      .valueChanges.pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(() => {
        this.delegateEmailErrorMessage = this.errorHelperService.getEmailErrorMessage(
          this.createDelegateForm.controls.delegateEmail
        );
      });

    this.updateEmailForm.valueChanges.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(() => {
      this.profileEmailErrorMessage = this.errorHelperService.getEmailErrorMessage(this.updateEmailForm.controls.email);
    });
  }

  setProfileUser(user: User) {
    this.profileUser = user;
    if (user && this.profileUser) {
      this.store.dispatch(getUserAvailableSites({ userId: user.id }));
      this.delegateUser = this.profileUser.delegate ? new User(this.profileUser.delegate) : null;
      const userFullName = `${user.firstName || ''} ${user.lastName || ''}`.trim();
      this.userFullName = !isEmpty(userFullName) ? userFullName : '—';
      this.email = !isEmpty(user.email) ? user.email : '—';
      const streetAddress = `${user.street1 || ''} ${user.street2 || ''}`.trim();
      this.streetAddress = streetAddress.length > 0 ? streetAddress : '—';
      this.city = !isEmpty(user.city) ? user.city : '—';
      this.state = !isEmpty(user.state) ? user.state : '—';
      this.company = !isEmpty(user.company) ? user.company : '—';
      this.title = !isEmpty(user.title) ? user.title : '—';
      this.zip = !isEmpty(user.zip) ? user.zip : '—';
      this.phone = !isEmpty(user.phone) ? user.phone.toString() : '—';
    }
  }

  isUpdateProfileFormValid(): boolean {
    return this.updateProfileForm.valid;
  }

  createForms(): void {
    this.createDelegateForm = this.formBuilder.group({
      delegateEmail: [null, [Validators.maxLength(254), Validators.pattern(RegexConstants.EMAIL), Validators.required]],
      delegateFirstName: ['', Validators.required],
      delegateLastName: ['', Validators.required],
      delegateTermsClicked: [false, Validators.requiredTrue],
    });
    this.updateProfileForm = this.formBuilder.group({
      firstName: [null, Validators.required],
      lastName: [null, Validators.required],
      company: null,
      title: null,
      address_id: null,
      street: [null, Validators.required],
      street2: null,
      city: [null, Validators.required],
      state: [null, Validators.required],
      zip: [null, Validators.required],
      phone: [null, [Validators.required]],
      mfaEnabled: [null],
    });
    this.updateEmailForm = this.formBuilder.group({
      email: [null, [Validators.maxLength(254), Validators.pattern(RegexConstants.EMAIL), Validators.required]],
    });
  }

  doStripeConnect(buyerId: number): void {
    if (!this.isAdmin) {
      // do api call to create stripe account uuid and save to user
      this.userService.doStripeConnect(
        this.profileUser.id,
        buyerId,
        this.getBuyerName(buyerId),
        this.profileUser.email
      );
    }
  }

  getLoginLink(buyerId: number): void {
    if (!this.isAdmin) {
      this.store.dispatch(resetStripeAccountRequiresAction());
      this.paymentService.getMyLoginUrl(buyerId);
    }
  }

  toggleAddDelegateControls(show: boolean): void {
    this.showAddDelegate = show;
    if (show) {
      this.createDelegateForm.reset();
    } else {
      this.createDelegateForm.markAsPristine();
    }
  }

  onDelegateSubmit(): void {
    this.createDelegateForm.markAsPristine();
    const newDelegate = {
      delegate_email: this.createDelegateForm.value.delegateEmail.toLowerCase(),
      delegate_first_name: this.createDelegateForm.value.delegateFirstName,
      delegate_last_name: this.createDelegateForm.value.delegateLastName,
    };
    this.store.dispatch(createDelegate({ data: newDelegate }));
    this.addingDelegate = this.createDelegateForm.value.delegateEmail.toLowerCase();
  }

  deleteDelegate(): void {
    const dialogRef = this.dialog.open(DialogYesNoComponent, {
      panelClass: 'fc-yes-no-dialog',
      data: {
        title: 'Are you sure you want to remove this delegate?',
        line1: 'They can be re-added at a later date.',
        buttonTextNo: 'Cancel',
        buttonTextYes: 'Remove',
      },
      autoFocus: false,
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result === true) {
        this.store.dispatch(removeDelegate());
      }
    });
  }

  toggleProfileEdit(): void {
    this.editingProfile = !this.editingProfile;
    this.updateProfileForm.controls.city.setValue(this.profileUser.city);
    this.updateProfileForm.controls.firstName.setValue(this.profileUser.firstName);
    this.updateProfileForm.controls.lastName.setValue(this.profileUser.lastName);
    this.updateProfileForm.controls.mfaEnabled.setValue(this.profileUser.mfaEnabled);
    this.updateProfileForm.controls.company.setValue(this.profileUser.company);
    this.updateProfileForm.controls.title.setValue(this.profileUser.title);
    this.updateProfileForm.controls.phone.setValue(this.profileUser.phone);
    this.updateProfileForm.controls.state.setValue(this.profileUser.state);
    this.updateProfileForm.controls.street2.setValue(this.profileUser.street2);
    this.updateProfileForm.controls.street.setValue(this.profileUser.street1);
    this.updateProfileForm.controls.zip.setValue(this.profileUser.zip);

    if (!this.isAdmin) {
      if (this.profileUser.mfaEnabled) {
        this.updateProfileForm.controls.phone.disable();
      } else {
        this.updateProfileForm.controls.phone.enable();
      }
    }

    // mark form as pristine so the user can navigate if they cancel
    if (!this.editingProfile) {
      this.updateProfileForm.markAsPristine();
    }
  }

  toggleEmailEdit(): void {
    this.editingEmail = !this.editingEmail;
    this.updateEmailForm.controls.email.setValue(this.profileUser.email);

    if (!this.editingEmail) {
      this.updateEmailForm.markAsPristine();
    }
  }

  setMfaEnabled(checkboxChange: MatCheckboxChange, value: boolean): void {
    checkboxChange.source.checked = value;
    this.updateProfileForm.controls.mfaEnabled.setValue(value);
    this.updateProfileForm.updateValueAndValidity();
  }

  confirmMFA(checkboxChange: MatCheckboxChange): void {
    if (!this.isAdmin) {
      if (checkboxChange.checked) {
        this.dialog
          .open(OtpDialogComponent, { data: { mode: 'verify-phone' } })
          .afterClosed()
          .subscribe((result: boolean): void => {
            this.setMfaEnabled(checkboxChange, result);
          });
      } else {
        this.dialog
          .open(DialogYesNoComponent, {
            panelClass: 'fc-confirmation-dialog',
            data: {
              title: 'Disable MFA?',
              line1:
                'You are about to disabled MFA. You will receive a confirmation code via SMS, which will complete your removal from MFA. If you no longer have access to the phone number above, please contact support to disable MFA and/or set up a new phone number. You can opt back in at any time.',
              buttonTextNo: 'Never Mind',
              buttonTextYes: 'Confirm',
            },
          })
          .afterClosed()
          .subscribe((result?: boolean): void => {
            if (result) {
              this.dialog
                .open(OtpDialogComponent, { data: { mode: 'verify-phone' } })
                .afterClosed()
                .subscribe((result: boolean): void => {
                  if (result) {
                    this.setMfaEnabled(checkboxChange, false);
                  } else {
                    this.setMfaEnabled(checkboxChange, true);
                  }
                });
            } else {
              this.setMfaEnabled(checkboxChange, true);
            }
          });
      }
    }
  }

  updateProfile(): void {
    const profile = {
      city: this.updateProfileForm.value.city,
      date_of_birth: new Date(this.profileUser.dateOfBirth).getTime() / 1000,
      drivers_license_num: this.profileUser.driversLicenseNum,
      drivers_license_state: this.profileUser.driversLicenseState,
      first_name: this.updateProfileForm.value.firstName,
      company: this.updateProfileForm.value.company,
      title: this.updateProfileForm.value.title,
      id: this.profileUser.id,
      last_name: this.updateProfileForm.value.lastName,
      mfa_enabled: this.updateProfileForm.controls.mfaEnabled.value,
      phone: this.updateProfileForm.controls.phone.value,
      ssn: this.profileUser.ssn,
      state: this.updateProfileForm.value.state,
      street_1: this.updateProfileForm.value.street,
      street_2: this.updateProfileForm.value.street2,
      zip: this.updateProfileForm.value.zip,
    };
    this.userService.updateUserProfile(profile).subscribe((user: User) => {
      // Checks if current user is changing themselves and only update current user if so
      if (this.currentUser.id === user.id) {
        this.currentUser = user;
        this.store.dispatch(setCurrentUser({ data: user }));
      }
      this.setProfileUser(user);
      this.toggleProfileEdit();
    });
  }

  updateEmail(): void {
    const email = this.updateEmailForm.value.email;
    if (email !== this.profileUser.email) {
      const dialogRef = this.dialog.open(ChangeEmailDialogComponent, {
        panelClass: 'fc-yes-no-dialog',
        autoFocus: false,
      });
      dialogRef.afterClosed().subscribe(result => {
        if (result === true) {
          this.userService.changeEmailAddress(this.profileUser.id, email).subscribe(() => {
            this.store.dispatch(logout());
          });
        }
      });
    }
    this.toggleEmailEdit();
  }

  verifyAccount(): void {
    if (this.profileUser && !this.profileUser.isVerified) {
      this.dialog
        .open(DialogManualIDComponent, {
          panelClass: 'fc-yes-no-dialog',
          data: {
            submitTimes: this.profileUser.truliooAttempts,
          },
        })
        .afterClosed()
        .subscribe(result => {
          if (result && result !== 'close') {
            const action = result;
            this.dialog
              .open(DialogYesNoComponent, {
                panelClass: 'fc-yes-no-dialog',
                data: {
                  title: 'Are You Sure?',
                  line1: `You are about to ${action} this user.`,
                  line2: 'Please confirm.',
                  buttonTextNo: 'Cancel',
                  buttonTextYes: 'Confirm',
                },
              })
              .afterClosed()
              .subscribe(result => {
                if (result) {
                  const verifyConfig = {
                    verify: {
                      verified: true,
                      clear: false,
                    },
                    deny: {
                      verified: false,
                      clear: false,
                    },
                    'clear Trulioo for': {
                      verified: false,
                      clear: true,
                    },
                  };

                  this.userService
                    .verifyUser(this.profileUser.id, verifyConfig[action].verified, verifyConfig[action].clear)
                    .subscribe((user: User) => {
                      this.setProfileUser(user);
                      this.store.dispatch(getAdminNotifications());
                    });
                }
              });
          }
        });
    }
  }

  sendAdminPasswordReset(): void {
    this.dialog
      .open(DialogYesNoComponent, {
        panelClass: 'fc-yes-no-dialog',
        data: {
          title: 'Please Confirm',
          line1: "This will reset this user's password immediately. Are you sure you want to continue?",
          line2: '',
          buttonTextYes: 'Yes, Reset',
          buttonTextNo: 'No, Cancel',
        },
      })
      .afterClosed()
      .subscribe(result => {
        if (result) {
          this.userService.adminResetPassword(this.id).subscribe((result: boolean) => {
            this.resetMessage = result ? 'Reset e-Mail Sent' : 'There was a problem resetting the password';
            setTimeout(() => {
              this.resetMessage = '';
            }, 3000);
          });
        }
      });
  }

  getBuyerName(buyerId: number) {
    if (this.buyers) {
      const buyer = this.buyers.find(b => b.id === buyerId);
      return buyer.name;
    }
    return '';
  }

  getBuyerSiteHeader(buyerId: number) {
    if (this.activeSites) {
      const noSites = this.activeSites.filter(p => p.cohort.buyer && p.cohort.buyerId == buyerId).length;
      return `${this.getBuyerName(buyerId)} Site${noSites == 1 ? '' : 's'} (${noSites})`;
    }
    return '';
  }

  buyerHasActiveSites(buyerId: number) {
    return this.activeSites && this.activeSites.filter(p => p.cohort.buyer && p.cohort.buyerId == buyerId).length > 0;
  }

  stripeAccountNeedsAction(buyerId: number) {
    if (this.notificationState?.landOwner.stripeAccountRequiresAction && this.profileUser?.stripeAccounts) {
      const stripeAccount = this.profileUser.stripeAccounts.find(sa => sa.buyerId === buyerId);
      return stripeAccount.isStripeAccountIssue;
    }
    return false;
  }

  openTrulioo() {
    if (this.profileUser && this.profileUser.truliooRecordId) {
      window.open(
        `https://portal.trulioo.com/verification?transactionRecordId=${this.profileUser.truliooRecordId}`,
        '_blank'
      );
    }
  }

  get verificationManuallyDenied(): boolean {
    return this.profileUser.rejectedBy !== null;
  }

  getUserType(user: User) {
    if (user.isAdmin) {
      return 'Admin';
    } else {
      return 'Landowner';
    }
  }

  get isFormDirty(): boolean {
    return this.updateProfileForm.dirty || this.updateEmailForm.dirty || this.createDelegateForm.dirty;
  }

  deleteUser() {
    this.dialog
      .open(DialogYesNoComponent, {
        panelClass: 'fc-yes-no-dialog',
        data: {
          title: 'Delete User',
          line1: 'This will permanently delete this user from CORE.',
          line2: 'Do you wish to continue?',
          buttonTextYes: 'Confirm',
          buttonTextNo: 'Cancel',
        },
        autoFocus: false,
      })
      .afterClosed()
      .subscribe(confirm => {
        if (confirm) {
          this.dialog
            .open(DialogYesNoComponent, {
              panelClass: 'fc-yes-no-dialog',
              data: {
                title: 'Final Confirmation',
                line1: 'This is not reversible.',
                line2: 'All user data and cancelled sites will be deleted!',
                buttonTextYes: 'Confirm',
                buttonTextNo: 'Cancel',
              },
              autoFocus: false,
            })
            .afterClosed()
            .subscribe(againConfirm => {
              if (againConfirm) {
                this.userService.deleteUser(this.profileUser.id).subscribe(result => {
                  if (result) {
                    this.router.navigate(['/admin/permissions']);
                  }
                });
              }
            });
        }
      });
  }

  navigateSendEmail(): void {
    this.router.navigate(['admin', 'emails', 'send-email'], {
      queryParams: { userId: this.id },
    });
  }

  getFloatLabelValue(): FloatLabelType {
    return this.updateProfileForm.controls.phone.value || 'auto';
  }
}
