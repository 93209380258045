import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { MatExpansionPanel } from '@angular/material/expansion';

import { SiteVerificationVisitsService, SuccessResponse } from 'src/app/core/api/site-verification-visits.service';

import { Site } from '../../models';
import { SiteVerificationVisit } from '../../models/site-verification-visit.model';

@Component({
  selector: 'fc-site-verification-visits',
  templateUrl: './site-verification-visits.component.html',
  styleUrls: ['./site-verification-visits.component.scss'],
})
export class SiteVerificationVisitsComponent implements OnInit {
  @ViewChild('matExpansionPanel', { static: false })
  matExpansionPanel: MatExpansionPanel;

  @Input()
  site: Site;

  @Input()
  isAdmin = false;

  @Input()
  isLimitedAdmin = false;

  @Input()
  startOpened = false;

  showAddNewForm = false;
  updateVisitIndex?: number;

  updateForm = this.formBuilder.group({
    date: [null, Validators.required],
    verificationType: [null, Validators.required],
  });

  addNewForm = this.formBuilder.group({
    date: [null, Validators.required],
    verificationType: [null, Validators.required],
  });

  visits: SiteVerificationVisit[] = [];

  showUpdateToast = false;
  showDeleteToast = false;

  constructor(private formBuilder: UntypedFormBuilder, private visitService: SiteVerificationVisitsService) {}

  ngOnInit(): void {
    this.visitService.getSiteVerificationVisits(this.site.id).subscribe((visits: SiteVerificationVisit[]) => {
      if (visits) {
        this.visits = [...visits].sort((a, b) => {
          return a.id - b.id;
        });
      }
    });
  }

  showAddNew($event: any): void {
    $event.stopPropagation();
    this.showAddNewForm = true;
  }

  showUpdateForm(index: number): void {
    this.updateVisitIndex = index;
    const { date, verificationType } = this.visits[index];

    this.updateForm.setValue({ date, verificationType });
  }

  submitNewVisit(): void {
    if (this.addNewForm.valid) {
      this.visitService
        .postSiteVerificationVisit(this.site.id, this.addNewForm.value)
        .subscribe((visit: SiteVerificationVisit) => {
          this.visits.unshift(visit);
          this.showAddNewForm = false;
          this.addNewForm.reset();
        });
    }
  }

  updateVisit(): void {
    if (this.updateForm.valid) {
      const id = this.visits[this.updateVisitIndex].id;
      this.visitService
        .patchSiteVerificationVisit(id, this.updateForm.value)
        .subscribe((visit: SiteVerificationVisit) => {
          this.visits.splice(this.updateVisitIndex, 1, visit);
          this.updateVisitIndex = undefined;
          this.showToast('update');
        });
    }
  }

  deleteVisit(id: number): void {
    this.visitService.deleteSiteVerificationVisit(id).subscribe((response: SuccessResponse) => {
      if (response.success) {
        this.visits = this.visits.filter(v => v.id !== id);
        this.showToast('delete');
      }
    });
  }

  showToast(type: string): void {
    switch (type) {
      case 'update':
        this.showUpdateToast = true;
        setTimeout(() => {
          this.showUpdateToast = false;
        }, 3000);
        break;
      case 'delete':
        this.showDeleteToast = true;
        setTimeout(() => {
          this.showDeleteToast = false;
        }, 3000);
        break;
    }
  }
}
