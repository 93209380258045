import { Base } from './base.model';
import { ReportConfig } from './report-config.model';

import { Type } from '../decorators/type.decorator';

export class ReportResponse extends Base {
  answers: any;
  reportType: string;
  @Type(ReportConfig) reportConfig: ReportConfig;
  reportConfigId: number;
  originalReportId: number;
  siteId: number;
  createDate: Date;
}
