import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { Store } from '@ngrx/store';
import { isEmpty } from 'lodash';
import { Observable, of } from 'rxjs';
import { map, skipWhile, switchMap, take } from 'rxjs/operators';

import { User } from '../../shared/models';
import * as fromApp from '../../store';
import { setCurrentUser } from '../../store/auth/auth.actions';
import { AuthState } from '../../store/auth/auth.reducer';
import { selectAuthState } from '../../store/auth/auth.selectors';
import { UserService } from '../api/user.service';

@Injectable({ providedIn: 'root' })
export class AuthGuard {
  constructor(private router: Router, readonly store: Store<fromApp.AppState>, readonly userService: UserService) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return this.store.select(selectAuthState).pipe(
      skipWhile(authState => isEmpty(authState)),
      take(1),
      switchMap((authState: AuthState): Observable<{ token: string; currentUser: User }> => {
        if (!authState.token) {
          return of({ token: null, currentUser: null, doUpdateState: false });
        } else if (!authState.currentUser) {
          return this.userService.getMe().pipe(
            map(res => ({
              token: authState.token,
              currentUser: res,
              doUpdateState: true,
            }))
          );
        } else {
          return of({
            token: authState.token,
            currentUser: authState.currentUser,
            doUpdateState: false,
          });
        }
      }),
      switchMap((authInfo: { token: string; currentUser: User; doUpdateState: boolean }) => {
        if (authInfo.currentUser && authInfo.doUpdateState) {
          this.store.dispatch(setCurrentUser({ data: authInfo.currentUser }));
        }
        if (authInfo.token && authInfo.currentUser) {
          // if we're logged in and trying to go to the promo
          // page go to the new site page instead
          if (state.url === '/') {
            this.router.navigate(['/site/new-site']);
            return of(false);
          } else if (route.data?.roles) {
            if (route.data.roles.filter(r => authInfo.currentUser.hasRole(r)).length > 0) {
              return of(true);
            } else {
              return of(false);
            }
          } else {
            return of(true);
          }
        } else if (state.url.startsWith('/auth')) {
          return of(true);
        } else {
          this.router.navigate(['/']);
          return of(false);
        }
      })
    );
  }
}
