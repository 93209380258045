import { Action, createReducer, on } from '@ngrx/store';
import { cloneDeep } from 'lodash';

import * as BuyerActions from './buyer.actions';

import { Buyer } from '../../shared/models';

export const buyerFeatureKey = 'buyer';

export interface BuyerState {
  buyers: Buyer[];
  preferredBuyer: Buyer;
  error: string;
}

export const initialState: BuyerState = {
  buyers: [],
  preferredBuyer: null,
  error: null,
};

const buyerReducer = createReducer(
  initialState,
  on(BuyerActions.getAllBuyers, (state, data) => {
    return initialState;
  }),
  on(BuyerActions.getAllBuyersSuccess, (state, data) => {
    return {
      ...state,
      buyers: data.data,
    };
  }),
  on(BuyerActions.getBuyerSuccess, (state, data) => {
    const buyers = cloneDeep(state.buyers || []);
    const idx = buyers.findIndex(r => r.id === data.data.id);
    if (idx > -1) {
      buyers[idx] = data.data;
    } else {
      buyers.push(data.data);
    }
    return {
      ...state,
      buyers,
    };
  }),
  on(BuyerActions.clearPreferredBuyer, (state, data) => {
    return {
      ...state,
      preferredBuyer: null,
    };
  }),
  on(BuyerActions.setPreferredBuyerSuccess, (state, data) => {
    return {
      ...state,
      preferredBuyer: data.data,
    };
  }),
  on(
    BuyerActions.getBuyerFailure,
    BuyerActions.getAllBuyersFailure,
    BuyerActions.setPreferredBuyerFailure,
    (state, data) => {
      let error = 'There was an error';
      if (data.error?.error?.detail) {
        error = data.error.error.detail;
      } else if (data.error?.message) {
        error = data.error.message;
      }
      return {
        ...state,
        preferredBuyer: null,
        error,
      };
    }
  )
);

export function reducer(state: BuyerState | undefined, action: Action) {
  return buyerReducer(state, action);
}
