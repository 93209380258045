import { Account } from './account.model';
import { Base } from './base.model';
import { Cohort } from './cohort.model';
import { PeriodicReport } from './periodic-report.model';
import { Program } from './program.model';
import { ReportResponse } from './report-response.model';
import { SiteStatus } from './site-status';
import { SiteReviewCheck } from './site_review_check.model';
import { SiteReviewNote } from './site_review_note.model';
import { User } from './user.model';
import { Version } from './version.model';
import { YieldCurve } from './yield-curve.model';

import { Type } from '../decorators/type.decorator';

export class Site extends Base {
  @Type(Account) public account: Account;
  public accountId: number;
  public activationDate: Date;
  public allowedClearing: number;
  public annualPayment: number;
  public approvedDate: Date;
  public area: number;
  @Type(User) public assignedAdmin?: User;
  public assignedAdminId?: number;
  @Type(Cohort) public availableCohorts: Cohort[];
  public buyerSelected: boolean;
  public canceledById: number;
  public canceledDate: Date;
  @Type(Cohort) public cohort: Cohort;
  public cohortId: number;
  public contractEnvelopeId: string;
  public createDate: Date;
  public createdAt?: string;
  public currentStep?: string;
  public eDocFulfilled: boolean;
  public forestryFirm: string;
  public hasRestrictions: boolean;
  public hasSmallClearing: boolean;
  public identifier: string;
  public initialAdjustment: number;
  public initialLat: number;
  public initialLng: number;
  public lastRegionUpdate: Date;
  public mortgageInformation: string;
  public name: string;
  public namesOnDeed: string;
  public netErt: number;
  public otherPrograms: string;
  public parcelOverlaps: boolean;
  @Type(PeriodicReport) public periodicReports: PeriodicReport[];
  public potentialArea: number;
  @Type(ReportResponse) public reportResponses: ReportResponse[];
  @Type(Program) public programs: Program[];
  public simulatedFinancials: any;
  public siteOverlaps: number[];
  @Type(SiteReviewCheck) public siteReviewChecks?: SiteReviewCheck[];
  @Type(SiteReviewNote) public siteReviewNotes?: SiteReviewNote[];
  public standOverage: number;
  public status: string;
  @Type(SiteStatus) public siteStatus: SiteStatus;
  public submittedDate: Date;
  public updatedAt: string;
  public valuation: string;
  public valuationCheck: number;
  @Type(Version) public versions: Version[];
  public yieldCurveVersion: number;
  @Type(YieldCurve) public yieldCurves: YieldCurve[];

  getTotalPaymentPeriods(cohort: Cohort) {
    let totalPeriods = 0;
    this.simulatedFinancials.cohort_financials.forEach(cohort_financial => {
      if (cohort.id == cohort_financial.cohort_id) {
        totalPeriods =
          (cohort_financial.total_years_in_site +
            cohort.initialFixedYears -
            cohort_financial.time_left_in_fixed_payment) *
          (12 / cohort.payPeriodCadence);
      }
    });
    return totalPeriods;
  }

  getYearsLeftInSite(cohort: Cohort) {
    let yearsLeft = 0;
    this.simulatedFinancials.cohort_financials.forEach(cohort_financial => {
      if (cohort.id == cohort_financial.cohort_id) {
        yearsLeft = cohort_financial.total_years_in_site;
      }
    });
    return yearsLeft;
  }

  getTotalFixedYearPayment(cohortId: number, average: boolean = false) {
    let payment = 0;
    this.simulatedFinancials.cohort_financials.forEach(cohort => {
      if (cohortId == cohort.cohort_id && cohort.total_fixed_payments !== undefined) {
        payment = cohort.total_fixed_payments;
        if (this.periodicReports?.[0]?.isSmallClearing) {
          payment = payment * cohort.clearing_ratio;
        }
        if (this.periodicReports?.[0]?.firewoodCutting) {
          payment -= cohort.firewood_deduction;
        }
        payment = payment * cohort.buffer_deduction_ratio;
        if (average) {
          payment = payment / cohort.time_left_in_fixed_payment;
        }
      }
    });
    return payment;
  }

  getEstimatedBasePayment(cohortId: number, forceClearing: boolean) {
    let payment = 0;
    this.simulatedFinancials.cohort_financials.forEach(cohort => {
      if (cohortId == cohort.cohort_id && cohort.total_fixed_payments !== null) {
        payment = cohort.base_yearly_payment;
        if (forceClearing && this.periodicReports?.[0]?.isSmallClearing) {
          payment = payment * cohort.clearing_ratio;
        }
        payment = payment * cohort.buffer_deduction_ratio;
      }
    });
    return payment;
  }

  getFirewoodDeductionRange(cohortId: number = null) {
    let values = [];
    this.simulatedFinancials.cohort_financials.forEach(cohort => {
      if (cohortId === null || cohortId == cohort.cohort_id) {
        values.push(cohort.firewood_deduction);
      }
    });
    if (values.length > 0) {
      return values.sort((a, b) => a - b);
    }
    return [0];
  }

  getClearingDeductionRange(cohortId: number = null) {
    let values = [];
    this.simulatedFinancials.cohort_financials.forEach(cohort => {
      if (cohortId === null || cohortId == cohort.cohort_id) {
        values.push(cohort.clearing_deduction);
      }
    });
    if (values.length > 0) {
      return values.sort((a, b) => a - b);
    }
    return [0];
  }

  getAverageAnnualRevenue(cohortId: number = null) {
    let values = [];
    this.simulatedFinancials.cohort_financials.forEach(cohort => {
      if ((cohortId === null || cohortId == cohort.cohort_id) && cohort.average_annual !== undefined) {
        let cohort_projection = cohort.average_annual ? cohort.average_annual : 0;
        if (this.submittedDate == null) {
          if (this.periodicReports?.[0]?.isSmallClearing) {
            cohort_projection = cohort_projection * cohort.clearing_ratio;
          }
          if (this.periodicReports?.[0]?.firewoodCutting) {
            cohort_projection -= cohort.firewood_deduction / cohort.total_years_in_site;
          }
          cohort_projection = cohort_projection * cohort.buffer_deduction_ratio;
        }
        values.push(cohort_projection);
      }
    });
    if (values.length > 0) {
      return values.sort((a, b) => a - b);
    }
    return [0];
  }

  getTwentyYearProjection(cohortId: number = null) {
    let values = [];
    this.simulatedFinancials.cohort_financials.forEach(cohort => {
      if ((cohortId === null || cohortId == cohort.cohort_id) && cohort.twenty_projection !== undefined) {
        let cohort_projection = cohort.twenty_projection ? cohort.twenty_projection : 0;
        if (this.submittedDate == null) {
          if (this.periodicReports?.[0]?.isSmallClearing) {
            cohort_projection = cohort.clearing_ratio * cohort_projection;
          }
          if (this.periodicReports?.[0]?.firewoodCutting) {
            cohort_projection -= cohort.firewood_deduction;
          }
          cohort_projection = cohort_projection * cohort.buffer_deduction_ratio;
        }
        values.push(cohort_projection);
      }
    });
    if (values.length > 0) {
      return values.sort((a, b) => a - b);
    }
    return [0];
  }

  getYearMarkString(extraYears: number = 0, cohortId: number = null) {
    const yearMarks = [];
    this.simulatedFinancials.cohort_financials.forEach(cohort => {
      if (cohortId === null || cohortId == cohort.cohort_id) {
        yearMarks.push(cohort.year_site_end + extraYears);
      }
    });
    if (yearMarks.length > 0) {
      const minYear = Math.min(...yearMarks);
      const maxYear = Math.max(...yearMarks);
      let yearMarkString = minYear.toString();
      if (minYear !== maxYear) {
        yearMarkString += '/' + maxYear.toString();
      }
      return yearMarkString;
    }
    return '';
  }
}
