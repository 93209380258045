import { Component, DestroyRef, inject, OnInit } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { MatDialog } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators';

import { DialogYesNoComponent } from '../../../shared/dialogs/dialog-yes-no/dialog-yes-no.component';
import { selectIsMobileView } from '../../../store/app-config/app-config.selectors';
import { selectPreferredBuyer } from '../../../store/buyer/buyer.selectors';
import * as fromApp from '../../../store/index';

@Component({
  selector: 'fc-new-site-page',
  templateUrl: './new-site-page.component.html',
  styleUrls: ['./new-site-page.component.scss'],
})
export class NewSitePageComponent implements OnInit {
  destroyRef = inject(DestroyRef);
  isMobile: boolean = false;
  isMobileViewSubscription: Subscription;

  constructor(readonly store: Store<fromApp.AppState>, private dialog: MatDialog) {}

  ngOnInit() {
    this.store
      .select(selectPreferredBuyer)
      .pipe(
        distinctUntilChanged(
          (oldPreferred, newPreferred) =>
            oldPreferred !== null && newPreferred !== null && oldPreferred.id === newPreferred.id
        ),
        takeUntilDestroyed(this.destroyRef)
      )
      .subscribe(preferredBuyer => {
        if (preferredBuyer !== null) {
          this.dialog.open(DialogYesNoComponent, {
            panelClass: 'fc-yes-no-dialog',
            data: {
              title: 'WELCOME ' + preferredBuyer.name.toUpperCase() + ' CUSTOMER',
              line1:
                'Welcome to CORE Carbon, ' + preferredBuyer.name + "'s preferred carbon offset management platform.",
              line2:
                'Within CORE, you will sign up for an account, create and manage your offset sites, and get paid directly from ' +
                preferredBuyer.name +
                ' for any active sites you create!',
              line3: 'Please click continue to enter our platform and begin the process.',
              buttonTextYes: 'CONTINUE',
            },
            autoFocus: false,
          });
        }
      });

    this.store
      .select(selectIsMobileView)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(mobileViewMode => {
        this.isMobile = mobileViewMode;
      });
  }
}
