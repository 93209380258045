import { Adjustment } from './adjustment.model';
import { Base } from './base.model';
import { PeriodicReport } from './periodic-report.model';
import { SitePayment } from './site-payment.model';

import { Type } from '../decorators/type.decorator';

export class PaymentPeriod extends Base {
  public siteVersionId: number;
  @Type(Adjustment) public adjustments: Adjustment[];
  public sequence: number;
  public startDate: Date;
  public endDate: Date;
  public ppt: number;
  public grossErt: number;
  public netErt: number;
  public deductionErt: number;
  public isVariablePeriod: boolean;
  public netPayout: number;
  public netPayoutAdjustment: number;
  public acrBufferErt: number;
  public reportResponseId: number;
  public internalBufferErt: number;
  @Type(SitePayment) public payment: SitePayment;
  @Type(PeriodicReport) public report: PeriodicReport;
}

export class PaymentPeriodSummary extends Base {
  public startDate: Date;
  public endDate: Date;
  public averagePpt: number;
  public grossErt: number;
  public deductionErt: number;
  public acrBufferErt: number;
  public internalBufferErt: number;
  public netPaidPayout: number;
  public netPaidErt: number;
  public netPayoutAdjustment: number;
  public netPendingPayout: number;
  public netPendingErt: number;
  public status: string;
}

export class PaymentPeriodDetail extends PaymentPeriodSummary {
  public buyerId: number;
  public siteId: number;
  public siteName: string;
  public acrProjectName: string;
  public cohortStartDate: Date;
  public cohortName: string;
  public submittedDate: Date;
  public reportResponseId: number;
  public stripeAccountCreationToken: string;
  public sequence: number;
  public siteVersionId: number;
}
