import * as turf from '@turf/turf';

import { Base } from './base.model';

export class Parcel extends Base {
  public gid: number;
  public parcelNumber: string;
  public llUuid: string;
  public llUpdatedAt: Date;
  public svg: string;
  public siteVersionId: number;
  public feature: any;
  public inRegion?: boolean = true;
  public owner?: string;
  public state?: string;
  public county?: string;
  public address?: string;

  getAreaInAcres() {
    if (this.feature) {
      return turf.area(this.feature) * 0.000247105;
    } else {
      return 0;
    }
  }
}
