<form [formGroup]="eligibilityForm" fcFormUnloadWarning>
  @for (question of displayReportConfig?.config; track question; let i = $index) {
    @if (question.answer_type === null) {
      <div class="annual-question-heading">
        <div class="heading-text">
          {{ question.question_text }}
        </div>
        <div class="overview-title readonly">
          {{ question.info_icon_text }}
        </div>
        <mat-divider></mat-divider>
      </div>
    } @else {
      <div
        class="instruction-body question-container"
        [ngClass]="eligibilityForm.controls['Custom_question_' + question.sequence].disabled ? 'readonly' : ''">
        @if (question.hide_number !== true) {
          <div class="number">{{ question.sequence + 1 }}.</div>
        }
        <div class="question-body">
          <span>
            @if (question.answer_type === 'checkbox') {
              <mat-checkbox [formControlName]="'Custom_question_' + question.sequence">
                {{ question.question_text }}
              </mat-checkbox>
            } @else {
              {{ question.question_text }}
              @if (question.info_icon_text !== null) {
                <fa-icon
                  #tooltip="matTooltip"
                  (click)="tooltip.toggle()"
                  [icon]="['far', 'circle-info']"
                  class="blue"
                  [matTooltip]="question.info_icon_text"
                  matTooltipClass="fc-tooltip"
                  matTooltipPosition="right"></fa-icon>
              }
            }
          </span>

          @if (question.answer_type === 'textarea') {
            <textarea [formControlName]="'Custom_question_' + question.sequence" rows="5"></textarea>
          }
          @if (question.answer_type === 'select') {
            <mat-form-field>
              <mat-select [formControlName]="'Custom_question_' + question.sequence" placeholder="Select One">
                @for (option of question.options; track $index) {
                  @if (option.name !== null) {
                    <mat-optgroup [label]="option.name">
                      @for (subOption of option.values; track $index) {
                        <mat-option [value]="subOption">{{ subOption }}</mat-option>
                      }
                    </mat-optgroup>
                  } @else {
                    @for (subOption of option.values; track $index) {
                      <mat-option [value]="subOption">{{ subOption }}</mat-option>
                    }
                  }
                }
              </mat-select>
            </mat-form-field>
          }
          @if (question.answer_type === 'bool') {
            <mat-radio-group
              class="no-error-box"
              [formControlName]="'Custom_question_' + question.sequence"
              (change)="customQuestionSelect($event, question)">
              <mat-radio-button [value]="true">Yes</mat-radio-button>
              <mat-radio-button [value]="false">No</mat-radio-button>
              @if (showPreviouslySelected && question.special_type === 'clearing') {
                <mat-radio-button [value]="">Previously Selected</mat-radio-button>
              }
            </mat-radio-group>
            @if (showPreviouslySelected && question.special_type === 'clearing') {
              <mat-hint>This is a one-time opt-in. No further response is requested.</mat-hint>
            } @else if (reportResponse === null && question.is_annual && annualQuestionsDisabled) {
              <mat-hint>This question is not applicable for this reporting period.</mat-hint>
            }
          }
          <!-- Implement additional comments if NOT hard stop and expected != given -->
          @if (question.special_type === 'program') {
            @if (eligibilityForm.controls['Custom_question_' + question.sequence].value) {
              <div class="select-program-container">
                <div>Please select all currently enrolled programs</div>
                <fc-program-list
                  [regionId]="activeSite?.cohort.region.id"
                  [selectedPrograms]="selectedPrograms"
                  [readonly]="reportResponse !== null"
                  (selectedProgramsChange)="selectProgram($event)"></fc-program-list>
              </div>
            }
            @if (hasRedProgram()) {
              <div class="error visible">
                This acreage is already included in one or more programs not compatible with enrollment in CORE Carbon.
              </div>
            }
          }
          @if (
            eligibilityForm.controls['Custom_question_' + question.sequence].value !== null &&
            eligibilityForm.controls['Custom_question_' + question.sequence].value !== question.expected_answer &&
            (question.add_details !== null || question.need_docs || question.allow_comments)
          ) {
            <div class="question-details">
              @if (question.add_details !== null) {
                <span [innerHTML]="question.add_details"></span>
              }
              @if (question.need_docs) {
                <div class="title">Uploaded Documents</div>
                <mat-divider [style]="'margin: 15px 0'"></mat-divider>
                @if (docFiles.length === 0 && reportResponse === null && !isPreview) {
                  <div (click)="selectDocument()" class="fc-link doc-select">
                    <fa-icon [icon]="['far', 'paperclip']" class="blue"></fa-icon>
                    Attach Document (Optional)
                  </div>
                } @else if (reportResponse === null) {
                  @for (docFile of docFiles; track docFile; let i = $index) {
                    <div class="doc-file">
                      <div>
                        <fa-icon [icon]="['fal', 'file']" class="gray"></fa-icon>
                        <div>{{ docFile.name }}</div>
                      </div>
                      <fa-icon
                        (click)="removeDocument($event, docFile.key)"
                        [icon]="['far', 'trash']"
                        class="gray"></fa-icon>
                    </div>
                  }
                  <mat-divider [style]="'margin: 15px 0'"></mat-divider>
                  <div *ngIf="docFiles.length > 0" class="add-document-link">
                    <div (click)="selectDocument()">+ Add Another Document</div>
                  </div>
                }
              }
              @if (question.allow_comments) {
                <div>Additional Details (optional)</div>
                <textarea [formControlName]="'Custom_question_comments_' + question.sequence" rows="5"></textarea>
              }
            </div>
          }
          @if (
            eligibilityForm.controls['Custom_question_' + question.sequence].invalid &&
            eligibilityForm.controls['Custom_question_' + question.sequence].value !== null
          ) {
            <div class="error visible">We're sorry, your site is not eligible based on the requirements provided.</div>
          }
        </div>
      </div>
    }
  }
</form>
<input
  #documentUpload
  accept=".pdf,.doc,.docx,.xls,.xlsx,.ppt,.pptx,.png,.jpg"
  id="documentUpload"
  name="documentUpload"
  style="display: none"
  type="file" />
