import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { environment } from '../../../environments/environment';
import { deserialize } from '../../shared/models/base-helper';
import { LegalPage } from '../../shared/models/legal-page.model';

interface DeserializedResponse {
  results: LegalPage[];
  total: number;
}
export interface AppConfigModel {
  id: number;
  key: string;
  value: string;
  description: string;
}

@Injectable({
  providedIn: 'root',
})
export class LegalService {
  apiUrl = environment.apiUrl + '/legal/';

  constructor(readonly http: HttpClient) {}

  getLegalPageContent(pageType: string): Observable<string> {
    return this.http.get<string>(`${this.apiUrl}type/${pageType}/`).pipe(
      map(response => {
        let legalPage: LegalPage = deserialize(response, LegalPage);
        return legalPage.pageHtml;
      })
    );
  }

  getLegalPageById(pageId: number): Observable<LegalPage> {
    return this.http.get<LegalPage>(`${this.apiUrl}${pageId}/`).pipe(map(response => deserialize(response, LegalPage)));
  }

  getAllLegalPages(filterString: string): Observable<DeserializedResponse> {
    const filterUrl = this.apiUrl + filterString;
    return this.http.get<any>(`${this.apiUrl}${filterString}`).pipe(
      map(response => {
        const deserializedResponse: DeserializedResponse = {
          results: [],
          total: response.total,
        };
        for (const config of response.results) {
          deserializedResponse.results.push(deserialize(config, LegalPage));
        }
        return deserializedResponse;
      })
    );
  }

  saveLegalPage(updatePage: LegalPage) {
    return this.http.patch<LegalPage>(`${this.apiUrl}`, updatePage).pipe(
      map(response => {
        return deserialize(response, LegalPage);
      })
    );
  }
}
