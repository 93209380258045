import { AbstractControl, UntypedFormGroup, ValidationErrors, ValidatorFn } from '@angular/forms';

import { RegexConstants } from '../constants/regex.constants';

export const passwordMatchValidator: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {
  const formGroup = control as UntypedFormGroup;
  const newPassword = formGroup.value.newPassword;
  const verifyPassword = formGroup.value.verifyPassword;
  return newPassword === verifyPassword ? null : { passwordMismatch: true };
};

export const passwordPolicyValidator: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {
  try {
    if (!control.value.match(RegexConstants.PASSWORD)) {
      return { security: true };
    }
  } catch (e) {
    return { security: true };
  }
  return null;
};

export const requiredFalse: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {
  return control.value === false ? null : { requiredFalse: true };
};
