import { Base } from './base.model';
import { Buyer } from './buyer.model';
import { Region } from './region.model';

import { Type } from '../decorators/type.decorator';

export class Cohort extends Base {
  public name: string;
  public startDate: Date;
  public enrollmentStartDate: Date;
  public enrollmentEndDate: Date;
  public regionId: number;
  @Type(Region) public region: Region;
  public buyerId: number;
  @Type(Buyer) public buyer: Buyer;
  public docusignOneTime: number;
  public docusignTemplateId: number;
  public payPeriodCadence: number;
  public internalBufferValue: number;
  public pricePerTon: number;
  public marketPricePercentage: number;
  public methodology: string;
  public minimumPaymentPerAcre: number;
  public minimumErtPerAcre: number;
  public initialFixedYears: number;
  public termsUrl: string;
  public terms1: string;
  public terms2: string;
  public terms3: string;
  public acrProjectName: string;
  public bonusAmount: number;
  public bonusLength: number;
  public bonusIsPpt: boolean;

  getTotalFixedYears(startDate: Date = new Date()) {
    let enrollmentStartEndDate = new Date(this.enrollmentStartDate);
    let monthsIntoSite = (startDate.getUTCFullYear() - enrollmentStartEndDate.getUTCFullYear()) * 12;
    monthsIntoSite -= enrollmentStartEndDate.getUTCMonth();
    monthsIntoSite += startDate.getUTCMonth();
    let daysIntoCurrentMonth =
      (startDate.getUTCDate() - 1) / new Date(startDate.getUTCFullYear(), startDate.getUTCMonth() + 1, 0).getDate();
    return this.initialFixedYears - (monthsIntoSite + daysIntoCurrentMonth) / 12;
  }

  getYearsLeft() {
    let siteStart = new Date(this.startDate);
    let todayDay = new Date();
    let monthsIntoSite = (todayDay.getUTCFullYear() - siteStart.getUTCFullYear()) * 12;
    monthsIntoSite -= siteStart.getUTCMonth();
    monthsIntoSite += todayDay.getUTCMonth();
    let daysIntoCurrentMonth =
      (todayDay.getUTCDate() - 1) / new Date(todayDay.getUTCFullYear(), todayDay.getUTCMonth() + 1, 0).getDate();
    return 20 - (monthsIntoSite + daysIntoCurrentMonth) / 12;
  }
}
