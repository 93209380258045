import { Component, DestroyRef, ElementRef, inject, OnInit, ViewChild } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { isEmpty } from 'lodash';
import moment from 'moment';
import { mergeMap } from 'rxjs/operators';

import { ReportService } from 'src/app/core/api/report.service';

import { DocumentService } from '../../../core/api/document.service';
import { SiteService } from '../../../core/api/site.service';
import { FormatHelperService } from '../../../core/helper/format-helper.service';
import { RedirectService } from '../../../core/helper/redirect.service';
import { SiteHelperService } from '../../../core/helper/site.service';
import * as fromApp from '../../../store';
import { selectAppConfigState } from '../../../store/app-config/app-config.selectors';
import { selectCurrentUser } from '../../../store/auth/auth.selectors';
import { setActiveSiteFailure } from '../../../store/site/site.actions';
import { LegalConstants } from '../../constants/legal.constants';
import { DialogYesNoComponent } from '../../dialogs/dialog-yes-no/dialog-yes-no.component';
import { Document, Site, User } from '../../models';
import { SpinnerService } from '../../spinner/spinner.service';

@Component({
  selector: 'fc-periodic-report',
  templateUrl: './periodic-report.component.html',
  styleUrls: ['./periodic-report.component.scss'],
})
export class PeriodicReportComponent implements OnInit {
  @ViewChild('documentUpload', { static: false })
  documentUpload: ElementRef;

  destroyRef = inject(DestroyRef);

  protected readonly LegalConstants = LegalConstants;
  showPreviousOption: boolean = false;
  id: number = null;
  reportId: number = null;
  currentUser: User = null;
  site: Site = null;
  reportForm: UntypedFormGroup;
  isAdmin: boolean = false;
  parcelCommaSeparatedList: string = null;
  statuses = [];
  firewoodQuestionDisabled: boolean = false;
  clearingQuestionDisabled: boolean = false;
  firewoodValuePerYearAdjustment = null;
  allowedClearing = '0.0';
  docFiles: any[] = [];
  leakageFileURL: string = null;
  beginningDate: Date = null;
  endDate: Date = null;
  submittedBy: User = null;

  constructor(
    public dialog: MatDialog,
    readonly store: Store<fromApp.AppState>,
    readonly router: Router,
    readonly siteHelper: SiteHelperService,
    readonly formatHelper: FormatHelperService,
    readonly documentService: DocumentService,
    readonly redirectService: RedirectService,
    private route: ActivatedRoute,
    private formBuilder: UntypedFormBuilder,
    readonly spinnerService: SpinnerService,
    readonly reportService: ReportService,
    readonly siteService: SiteService
  ) {
    this.createForm();
  }

  ngOnInit() {
    this.statuses = this.siteHelper.siteStatusList;
    this.store
      .select(selectAppConfigState)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(appConfig => {
        this.leakageFileURL = appConfig.config['leakage_file_url'];
      });

    this.id = +this.route.snapshot.params.id;
    if (this.route.snapshot.params.reportId) {
      this.reportId = +this.route.snapshot.params.reportId;
    }

    this.siteService.getSite(this.id).subscribe(
      site => {
        this.site = site;
        let paymentPeriods = this.site.versions[0].paymentPeriods
          .filter(p => !p.report)
          .sort((a, b) => a.sequence - b.sequence);
        if (this.reportId) {
          paymentPeriods = this.site.versions[0].paymentPeriods
            .filter(p => p.report?.id === this.reportId)
            .sort((a, b) => a.sequence - b.sequence);
          const firstPeriodWithClearing = this.site.versions[0].paymentPeriods
            .filter(p => p.report?.isSmallClearing)
            .sort((a, b) => a.sequence - b.sequence)[0];
          if (firstPeriodWithClearing && paymentPeriods[0].sequence > firstPeriodWithClearing.sequence) {
            this.showPreviousOption = true;
            this.reportForm.controls.isSmallClearing.setValue(null);
          }
        }

        this.parcelCommaSeparatedList = this.site.versions[0].parcels.map(p => p.parcelNumber).join(', ');
        this.allowedClearing = this.site.allowedClearing.toFixed(1);
        this.beginningDate = paymentPeriods[0].sequence > 0 ? paymentPeriods[0].startDate : this.site.submittedDate;
        this.endDate = paymentPeriods[0].endDate;
        let annualQuestionsDisabled = this.areAnnualQuestionsDisabled(paymentPeriods[0].sequence);
        paymentPeriods.forEach(pp => {
          if (pp.endDate > this.endDate && moment().isAfter(pp.endDate)) {
            this.endDate = pp.endDate;
            annualQuestionsDisabled = annualQuestionsDisabled && this.areAnnualQuestionsDisabled(pp.sequence);
          }
        });
        this.firewoodValuePerYearAdjustment = this.site.getFirewoodDeductionRange()[0];
        this.firewoodQuestionDisabled = annualQuestionsDisabled || this.firewoodValuePerYearAdjustment == 0;
        if (this.firewoodQuestionDisabled) {
          this.reportForm.controls.firewoodCutting.disable();
        } else {
          this.reportForm.controls.firewoodCutting.setValidators([Validators.required]);
        }

        this.clearingQuestionDisabled = annualQuestionsDisabled || this.site.hasSmallClearing;
        if (this.clearingQuestionDisabled) {
          if (this.reportId === null) {
            this.reportForm.controls.isSmallClearing.setValue(null);
            this.reportForm.controls.isSmallClearing.disable();
          }
        } else {
          this.reportForm.controls.isSmallClearing.setValidators([Validators.required]);
        }
      },
      error => this.store.dispatch(setActiveSiteFailure({ error }))
    );

    this.store
      .select(selectCurrentUser)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(currentUser => {
        this.currentUser = currentUser;
        this.isAdmin = currentUser.isAdmin;
      });

    if (this.reportId) {
      this.reportService
        .getPeriodicReport(this.reportId)
        .pipe(takeUntilDestroyed(this.destroyRef))
        .subscribe(report => {
          this.reportForm.controls.stillOwnLand.setValue(report.stillOwnLand);
          this.reportForm.controls.inComplianceContract.setValue(report.inComplianceContract);
          this.reportForm.controls.waysNotInCompliance.setValue(report.notInComplianceDesc);
          this.reportForm.controls.inComplianceLaw.setValue(report.inComplianceLaw);
          this.reportForm.controls.anyDisturbances.setValue(report.disturbances);
          this.reportForm.controls.disturbanceDesc.setValue(report.disturbancesDesc);
          this.reportForm.controls.activityLeakage.setValue(report.activityLeakage);
          this.reportForm.controls.leakageInfo.setValue(report.leakageInfo);
          this.reportForm.controls.salvageCut.setValue(report.salvageCut);
          this.reportForm.controls.hasDrained.setValue(report.hasDrained);
          this.reportForm.controls.firewoodCutting.setValue(report.firewoodCutting);
          this.reportForm.controls.isSmallClearing.setValue(report.isSmallClearing);
          this.reportForm.controls.feedback.setValue(report.feedback);
          if (this.readOnly) {
            this.reportForm.disable();
          }
          if (report.submittedBy) {
            this.submittedBy = report.submittedBy;
          }
        });
    }
  }

  createForm(): void {
    this.reportForm = this.formBuilder.group({
      stillOwnLand: ['', Validators.required],
      inComplianceContract: ['', Validators.required],
      waysNotInCompliance: '',
      inComplianceLaw: ['', Validators.required],
      anyDisturbances: ['', Validators.required],
      disturbanceDesc: '',
      activityLeakage: ['', Validators.required],
      leakageInfo: '',
      salvageCut: ['', Validators.required],
      hasDrained: ['', Validators.required],
      firewoodCutting: '',
      isSmallClearing: '',
      feedback: '',
      legalAck: [false, Validators.requiredTrue],
    });
  }

  getStandCount(): number {
    let standCount = 0;
    if (this.site?.versions.length > 0 && this.site.versions[0].stands) {
      standCount = this.site.versions[0].stands.length;
    }
    return standCount;
  }

  getOwnerName() {
    if (this.site?.account?.user) {
      return this.site.account.user.getDisplayName({});
    }
  }

  getArea(): number {
    if (this.site?.area) {
      return Math.round(this.site.area);
    }
    return 0;
  }

  submitButtonDisabled(): boolean {
    return (
      !this.reportForm.valid ||
      (this.reportForm.value.inComplianceContract && isEmpty(this.reportForm.controls.waysNotInCompliance)) ||
      (this.reportForm.controls.anyDisturbances.value && isEmpty(this.reportForm.value.disturbanceDesc)) ||
      (this.reportForm.value.activityLeakage && this.docFiles.length === 0)
    );
  }

  submitReport(): void {
    this.spinnerService.show('app-spinner');
    const newReport = {
      still_own_land: this.reportForm.value.stillOwnLand,
      in_compliance_contract: this.reportForm.value.inComplianceContract,
      not_in_compliance_desc: this.reportForm.controls.waysNotInCompliance.value,
      in_compliance_law: this.reportForm.value.inComplianceLaw,
      disturbances: this.reportForm.value.anyDisturbances,
      disturbances_desc: this.reportForm.value.disturbanceDesc,
      activity_leakage: this.reportForm.value.activityLeakage,
      leakage_info: this.reportForm.controls.leakageInfo.value,
      salvage_cut: this.reportForm.value.salvageCut,
      has_drained: this.reportForm.value.hasDrained,
      firewood_cutting: this.reportForm.value.firewoodCutting,
      is_small_clearing: this.reportForm.value.isSmallClearing,
      feedback: this.reportForm.controls.feedback.value,
    };
    this.reportService.submitPeriodicReport(this.site.id, newReport).subscribe(
      () => {
        this.reportForm.markAsPristine();
        if (newReport.disturbances) {
          this.dialog
            .open(DialogYesNoComponent, {
              panelClass: 'fc-yes-no-dialog',
              data: {
                title: 'Disturbance Detected',
                line1:
                  'You have indicated that a disturbance occurred. Please provide some additional information to allow us to better re-assess your property.',
                titleAlign: 'left',
                buttonTextYes: 'Proceed',
              },
            })
            .afterClosed()
            .subscribe(() =>
              this.redirectService.redirectTo(['account', 'site', this.id.toString(), 'disturbance-report'])
            );
        } else {
          this.redirectService.redirectToDashboard();
        }
      },
      error => {
        throw new Error(error.message);
      },
      () => this.spinnerService.hide('app-spinner')
    );
  }

  onAdjustmentChanged(event: any, question: string): void {
    if (event.value) {
      let dialogRef = null;
      if (question === 'firewoodCutting') {
        dialogRef = this.siteHelper.showFirewoodDialog(
          this.formatHelper.formatMinMaxValuation(this.site.getFirewoodDeductionRange(this.site.cohortId))
        );
      } else {
        dialogRef = this.siteHelper.showClearingDialog(
          this.allowedClearing,
          this.formatHelper.formatMinMaxValuation(this.site.getClearingDeductionRange())
        );
      }
      dialogRef.afterClosed().subscribe((agree: boolean) => {
        if (!agree) {
          const patch = {};
          patch[question] = null;
          this.reportForm.patchValue(patch);
        }
      });
    }
  }

  areAnnualQuestionsDisabled(periodNo: number): boolean {
    // ask every 12 months
    return periodNo % (12 / this.site.cohort.payPeriodCadence) != 0;
  }

  selectDocument() {
    const documentUpload = this.documentUpload.nativeElement;
    documentUpload.onchange = () => {
      if (documentUpload.files.length === 1) {
        const file = documentUpload.files[0];
        if (!file.name.startsWith('.')) {
          let key = null;
          let name = file.name;
          this.documentService
            .uploadFile(this.site.identifier, file)
            .pipe(
              mergeMap(fileKey => {
                key = fileKey;
                const newDoc = new Document({
                  siteId: this.site.id,
                  documentTypeId: this.documentService.getDocTypeId('Other'),
                  name: `RPX - Leakage - ${file.name}`,
                  identifier: fileKey,
                  userId: this.currentUser.id,
                });
                return this.documentService.createDocument(newDoc);
              })
            )
            .subscribe(
              () => {
                documentUpload.value = '';
                this.docFiles.push({ name, key });
              },
              () => {
                //
              }
            );
        }
      }
    };
    documentUpload.click();
  }

  removeDocument(event: any, key: string) {
    const idx = this.docFiles.findIndex(d => d.key === key);
    if (idx !== -1) {
      this.documentService.deleteDocument(this.site.identifier, key).subscribe(() => {
        this.docFiles.splice(idx, 1);
      });
    }
  }

  get projectedNext12MonthRevenue() {
    return this.formatHelper.formatMinMaxValuation(this.site.getAverageAnnualRevenue());
  }

  get hasActivityLeakage(): boolean {
    const hasLeakage = this.reportForm.get('activityLeakage').value;
    if (!hasLeakage && this.docFiles.length > 0) {
      this.docFiles = [];
    }
    return hasLeakage;
  }

  get readOnly(): boolean {
    return this.isAdmin || this.reportId != null;
  }

  get isFormDirty(): boolean {
    return this.reportForm.dirty;
  }
}
