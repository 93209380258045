import { Component, DestroyRef, inject, Input, OnDestroy } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { ControlValueAccessor, FormGroupDirective, NgControl, NgForm, UntypedFormControl } from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';

@Component({
  selector: 'fc-control',
  template: ``,
})
export class FCControlComponent<T> implements ControlValueAccessor, OnDestroy {
  destroyRef = inject(DestroyRef);
  private destroy$ = new Subject<void>();
  private _showErrorSubject = new BehaviorSubject<boolean>(undefined);
  private _showError$: Observable<boolean> = this._showErrorSubject.asObservable();

  public disabled = false;
  public formControl = new UntypedFormControl('');
  public invalid = false;
  public value: T;

  public errorMatcher = new FCErrorMatcher(this._showError$, this.destroy$.asObservable());

  constructor(public ngControl: NgControl) {
    ngControl.valueAccessor = this;
  }

  ngOnDestroy() {
    this.destroy$.next();
  }

  @Input()
  public set showError(v: boolean) {
    this._showErrorSubject.next(v);
  }

  public onChange(newVal: T) {
    this.value = newVal;
  }

  public onTouched(_?: any) {}

  public registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  public registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  public setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  public writeValue(obj: T): void {
    this.value = obj;
  }
}

export class FCErrorMatcher implements ErrorStateMatcher {
  destroyRef = inject(DestroyRef);
  private hasError: boolean = undefined;

  constructor(hasError$: Observable<boolean>, destroy$: Observable<void>) {
    hasError$.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(hasError => {
      this.hasError = hasError;
    });
  }

  public isErrorState(control: UntypedFormControl, form: NgForm | FormGroupDirective): boolean {
    const isSubmitted = form?.submitted;
    const isFromDirtyAndSubmitted = control?.invalid && (!form || isSubmitted) && (control.dirty || control.touched);
    return this.hasError ?? isFromDirtyAndSubmitted;
  }
}
