import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { environment } from '../../../environments/environment';
import { PaymentPeriod, SitePayment, User } from '../../shared/models';
import { deserialize, serialize } from '../../shared/models/base-helper';

export interface StripeLoginLink {
  object: 'login_link';
  created: number;
  url: string;
  id: string;
}

interface PaymentData {
  site_id: number;
  period: PaymentPeriod;
}

export interface CalcIsPayableRequest {
  site_ids: Array<number>;
}

export interface PaymentSitesSerializedResponse {
  payments: Array<any>;
  total_paid_in_cents: number;
  has_error: boolean;
}

export interface PaymentSitesResponse {
  payments: Array<SitePayment>;
  totalPaidInCents: number;
  hasError: boolean;
}

export interface PaymentAvailableSerializedResponse {
  site_id: number;
  is_payable: boolean;
}

export interface PaymentAvailableResponse {
  siteId: number;
  isPayable: boolean;
}

export interface SendStripeEmailRequest {
  site_ids: Array<number>;
}

export interface GetStripeBalanceSerializedRequest {
  buyer_ids: Array<number>;
}

export interface GetStripeBalanceSerializedResponse {
  balances: Array<SerializedBalanceData>;
}

export interface SerializedBalanceData {
  buyer_id: number;
  buyer_name: number;
  amount: number;
}

export interface BalanceData {
  buyerId: number;
  buyerName: number;
  amount: number;
}

@Injectable({
  providedIn: 'root',
})
export class PaymentService {
  apiUrl = environment.apiUrl + '/payments/';

  constructor(readonly http: HttpClient) {}

  authenticateAccount(code: string, buyerId: number): Observable<User> {
    let filters = new HttpParams();
    filters = filters.append('code', code);
    filters = filters.append('buyer_id', buyerId.toString());

    return this.http
      .get<any>(`${this.apiUrl}authenticate_account`, {
        params: filters,
      })
      .pipe(map((user: any) => deserialize(user, User)));
  }

  getMyLoginUrl(buyerId: number) {
    let params = new HttpParams();
    params = params.append('buyer_id', buyerId.toString());

    this.http
      .get<StripeLoginLink>(`${this.apiUrl}my_login_url`, {
        params,
      })
      .subscribe((response: any) => {
        if (response?.url) {
          let ua = window.navigator.userAgent;
          let iOS = !!/iPad/.exec(ua) || !!/iPhone/.exec(ua);
          let webkit = !!/WebKit/.exec(ua);
          let iOSSafari = iOS && webkit && !/CriOS/.exec(ua);
          if (iOSSafari) {
            window.location.href = response.url;
          } else {
            window.open(response.url);
          }
        }
      });
  }

  sendStripeEmails(siteIds: Array<number>): Observable<Array<string>> {
    const body: SendStripeEmailRequest = {
      site_ids: siteIds,
    };
    return this.http
      .post<SendStripeEmailRequest>(`${this.apiUrl}stripe-emails`, body)
      .pipe(map((value: any) => value.emails));
  }

  paySites(data: Array<PaymentData>): Observable<PaymentSitesResponse> {
    const sites = data.map(d => {
      return {
        site_id: d.site_id,
        period: serialize(d.period),
      };
    });
    const payload = { sites };
    return this.http.post<PaymentSitesSerializedResponse>(`${this.apiUrl}pay_sites`, payload).pipe(
      map((response: PaymentSitesSerializedResponse) => {
        return {
          payments: response.payments.map(serializedPayment => deserialize(serializedPayment, SitePayment)),
          totalPaidInCents: response.total_paid_in_cents,
          hasError: response.has_error,
        };
      })
    );
  }

  getHistory(siteId: number): Observable<SitePayment[]> {
    return this.http
      .get<any[]>(`${this.apiUrl}history?site_id=${siteId}`)
      .pipe(map(payments => payments.map((serializedPayment: any) => deserialize(serializedPayment, SitePayment))));
  }

  recordPayment({ data }): Observable<SitePayment> {
    const payment = { date: data.date, amount: data.amount, note: data.note };
    const url = `${this.apiUrl}record?site_id=${data.siteId}`;
    return this.http
      .post<any>(url, payment)
      .pipe(map((serializedPayment: any) => deserialize(serializedPayment, SitePayment)));
  }

  expressPayment({ data }): Observable<SitePayment> {
    const payment = { date: data.date, amount: data.amount, note: data.note };
    const url = `${this.apiUrl}express?site_id=${data.siteId}`;
    return this.http
      .post<any>(url, payment)
      .pipe(map((serializedPayment: any) => deserialize(serializedPayment, SitePayment)));
  }

  getStripeBalance(buyerIds: number[]): Observable<Array<BalanceData>> {
    const body: GetStripeBalanceSerializedRequest = {
      buyer_ids: buyerIds,
    };

    return this.http.post<GetStripeBalanceSerializedResponse>(`${this.apiUrl}stripe-balance`, body).pipe(
      map((response: GetStripeBalanceSerializedResponse) =>
        response.balances.map((r: SerializedBalanceData) => ({
          buyerId: r.buyer_id,
          buyerName: r.buyer_name,
          amount: r.amount,
        }))
      )
    );
  }
}
