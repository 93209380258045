import { Component, DestroyRef, inject, Input, OnInit } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { MatDialog } from '@angular/material/dialog';
import { MatSelectChange } from '@angular/material/select';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { round } from '@turf/turf';
import { skipWhile } from 'rxjs/operators';

import { setActiveSite, setActiveSiteSuccess } from 'src/app/store/site/site.actions';

import { SiteService } from '../../../core/api/site.service';
import { UserService } from '../../../core/api/user.service';
import { FormatHelperService } from '../../../core/helper/format-helper.service';
import { SiteHelperService } from '../../../core/helper/site.service';
import * as fromApp from '../../../store';
import { selectActiveSite } from '../../../store/site/site.selectors';
import { DialogChangeOwnerComponent } from '../../dialogs/dialog-change-owner/dialog-change-owner.component';
import { DialogChangeSiteNameComponent } from '../../dialogs/dialog-change-site-name/dialog-change-site-name.component';
import { Parcel, Program, Site, User } from '../../models';

@Component({
  selector: 'fc-site-detail-overview',
  templateUrl: './site-detail-overview.component.html',
  styleUrls: ['./site-detail-overview.component.scss'],
})
export class SiteDetailOverviewComponent implements OnInit {
  tooBig: boolean = false;
  tooSmall: boolean = false;
  site: Site = null;
  programs: Program[];
  parcelData: Parcel[] = [];
  destroyRef = inject(DestroyRef);

  twentyYearMark: string = null;
  twentyYearProjection: string = 'N/A';

  admins: User[] = [];
  assignedAdminId: number = -1;

  assignedAdminChange($event: MatSelectChange) {
    this.siteService.setAssignedAdmin(this.site.id, $event.value).subscribe((site: Site) => (this.site = site));
  }

  @Input()
  isAdmin = false;

  @Input()
  isSuperAdmin = false;

  parcelArea = 0;
  userName: string;
  delegateName = '--';
  acres: string;
  hasOutdatedParcels: boolean = false;
  publicOverlap: number = 0;

  constructor(
    readonly store: Store<fromApp.AppState>,
    private router: Router,
    public dialog: MatDialog,
    readonly userService: UserService,
    readonly siteService: SiteService,
    readonly siteHelper: SiteHelperService,
    readonly formatHelper: FormatHelperService
  ) {}

  ngOnInit() {
    this.store
      .select(selectActiveSite)
      .pipe(
        takeUntilDestroyed(this.destroyRef),
        skipWhile(site => site === null)
      )
      .subscribe(site => {
        this.site = site;
        if (this.isAdmin && site.submittedDate !== null && site.activationDate === null) {
          this.siteService.siteNeedsRestart(this.site.id).subscribe(restart => {
            if (restart) {
              this.hasOutdatedParcels = true;
            }
          });
          this.siteService.sitePublicOverlap(this.site.id).subscribe(overlapAcres => {
            if (overlapAcres) {
              this.publicOverlap = overlapAcres;
            }
          });
        }
        this.tooBig = this.site.area > 5000;
        this.tooSmall = this.site.area < 40;
        this.parcelData = this.site.versions[0].parcels;
        if (this.parcelData) {
          this.parcelArea = 0;
          this.parcelData.forEach((parcel: Parcel) => {
            this.parcelArea += parcel.getAreaInAcres();
          });
        }

        this.userName = this.site.account.user.getDisplayName();
        if (this.site.account.user.delegate) {
          this.delegateName = this.site.account.user.delegate.getDisplayName();
        }

        this.acres = this.site.area
          ? this.site.area.toLocaleString(undefined, {
              maximumFractionDigits: 0,
            })
          : '0';

        this.twentyYearMark = this.site.getYearMarkString(0);
        this.twentyYearProjection = this.formatHelper.formatMinMaxValuation(this.site.getTwentyYearProjection());
        if (this.isAdmin) {
          this.userService
            .getAdmins()
            .pipe(skipWhile(() => this.site === null))
            .subscribe((admins: User[]) => (this.admins = admins));
          if (this.site.assignedAdminId) {
            this.assignedAdminId = this.site.assignedAdminId;
          }
        }
      });
  }

  isSiteSubmittedNotActive(): boolean {
    return this.site && this.site.activationDate === null && this.site.submittedDate !== null;
  }

  navigateByID(id: number): void {
    this.router.navigate([`/admin/site/${id}`]);
  }

  hasOverlaps(): boolean {
    return this.site.siteOverlaps.length > 0;
  }

  hasParcelOverlap(): boolean {
    return this.site.parcelOverlaps;
  }

  hasStandOverage(): boolean {
    return this.site.standOverage > 0.0;
  }

  hasValuationIssue(): boolean {
    return (
      this.site.valuation &&
      round(parseFloat(this.site.valuation.split(',')[0]), 1) !== round(this.site.valuationCheck, 1)
    );
  }

  changeOwner(): void {
    const dialogRef = this.dialog.open(DialogChangeOwnerComponent, {
      panelClass: 'fc-dialog-change-owner',
      data: {
        currentOwner: this.site.account.user,
        siteName: this.site.name,
      },
      autoFocus: false,
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result?.user) {
        this.siteService.updateSiteOwner(this.site.id, result.user.id).subscribe(updatedSite => {
          this.store.dispatch(setActiveSite({ id: this.site.id }));
        });
      }
    });
  }

  changeSiteName(): void {
    const dialogRef = this.dialog.open(DialogChangeSiteNameComponent, {
      panelClass: 'fc-dialog-change-site-name',
      autoFocus: false,
    });

    dialogRef.afterClosed().subscribe((result: Site) => {
      if (result) {
        this.siteService.updateSiteName(this.site.id, result.name).subscribe(
          updatedSite => {
            this.store.dispatch(setActiveSiteSuccess({ data: updatedSite }));
          },
          error => {
            console.error(error);
          }
        );
      }
    });
  }

  get projectedNext12MonthRevenue() {
    return this.formatHelper.formatMinMaxValuation(this.site.getAverageAnnualRevenue());
  }
}
