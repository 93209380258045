import { HttpClient } from '@angular/common/http';
import { DestroyRef, inject, Injectable } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { Store } from '@ngrx/store';

import { User } from '../../shared/models';
import { selectAuthState } from '../../store/auth/auth.selectors';
import * as fromApp from '../../store/index';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  destroyRef = inject(DestroyRef);
  exp: number = null;
  token: string = null;
  actAsUser: User = null;

  constructor(readonly http: HttpClient, readonly store: Store<fromApp.AppState>) {
    this.store
      .select(selectAuthState)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(authState => {
        if (this.token !== authState.token) {
          this.token = authState.token;
          this.exp = authState.exp;
        }
        this.actAsUser = authState.actAsUser;
      });
  }

  getToken(): string {
    return this.token || localStorage.getItem('token');
  }

  getExp(): number {
    return this.exp || parseInt(localStorage.getItem('exp'), 10);
  }

  getActAsUser(): string {
    return this.actAsUser ? this.actAsUser.cognitoSubject : null;
  }
}
