import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { environment } from '../../../environments/environment';
import { DisturbanceReport, PeriodicReport } from '../../shared/models';
import { deserialize } from '../../shared/models/base-helper';
import { ReportConfig } from '../../shared/models/report-config.model';
import { ReportResponse } from '../../shared/models/report-response.model';

interface DeserializedResponse {
  results: ReportConfig[];
  total: number;
}
@Injectable({
  providedIn: 'root',
})
export class ReportService {
  apiUrl = environment.apiUrl + '/reports/';

  constructor(readonly http: HttpClient) {}

  getReportConfig(methodology: string, reportType: string): Observable<any> {
    return this.http.get<any>(`${this.apiUrl}report_config/${reportType}/${methodology}/`).pipe(
      map((response: any) => {
        return deserialize(response, ReportConfig);
      })
    );
  }

  getReportConfigById(id: number): Observable<any> {
    return this.http.get<any>(`${this.apiUrl}report_config/${id}/`).pipe(
      map((response: any) => {
        return deserialize(response, ReportConfig);
      })
    );
  }

  submitPeriodicReport(siteId: number, periodicReport: any): Observable<PeriodicReport> {
    return this.http.post<any>(`${this.apiUrl}periodic?site_id=${siteId}`, periodicReport).pipe(
      map((serializedPeriodicReport: any) => {
        return deserialize(serializedPeriodicReport, PeriodicReport);
      })
    );
  }

  getPeriodicReport(id: number): Observable<PeriodicReport> {
    return this.http
      .get<any>(`${this.apiUrl}periodic/${id}`)
      .pipe(map(serializedPeriodicReport => deserialize(serializedPeriodicReport, PeriodicReport)));
  }

  submitDisturbanceReport(siteId: number, disturbanceReport: any): Observable<DisturbanceReport> {
    return this.http
      .post<any>(`${this.apiUrl}disturbance?site_id=${siteId}`, disturbanceReport)
      .pipe(map((serializedDisturbanceReport: any) => deserialize(serializedDisturbanceReport, DisturbanceReport)));
  }

  getDisturbanceReport(id: number): Observable<DisturbanceReport> {
    return this.http
      .get<any>(`${this.apiUrl}disturbance/${id}`)
      .pipe(map(serializedDisturbanceReport => deserialize(serializedDisturbanceReport, DisturbanceReport)));
  }

  submitReportResponse(siteId: number, reportResponse: ReportResponse) {
    return this.http.post<any>(`${this.apiUrl}report-response?site_id=${siteId}`, reportResponse).pipe(
      map((response: any) => {
        return deserialize(response, ReportResponse);
      })
    );
  }

  getAllReportConfig(filterString: string): Observable<DeserializedResponse> {
    const filterUrl = this.apiUrl + 'report_config/' + filterString;
    return this.http.get<any>(filterUrl).pipe(
      map(response => {
        const deserializedResponse: DeserializedResponse = {
          results: [],
          total: response.total,
        };
        for (const config of response.results) {
          deserializedResponse.results.push(deserialize(config, ReportConfig));
        }
        return deserializedResponse;
      })
    );
  }

  saveReportConfig(config: ReportConfig) {
    return this.http.patch<any>(`${this.apiUrl}report_config/`, config).pipe(
      map(response => {
        return deserialize(response, ReportConfig);
      })
    );
  }
}
