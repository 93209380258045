import { Component, DestroyRef, ElementRef, inject, OnInit, ViewChild } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';

import { environment } from '../../../../environments/environment';
import { LegalService } from '../../../core/api/legal-page.service';
import * as fromApp from '../../../store';
import { selectAppConfigState } from '../../../store/app-config/app-config.selectors';
import { LegalConstants } from '../../constants/legal.constants';

@Component({
  selector: 'fc-legal-page',
  templateUrl: './legal-page.component.html',
  styleUrls: ['./legal-page.component.scss'],
})
export class LegalPageComponent implements OnInit {
  @ViewChild('legalContent', { static: true })
  pageContent: ElementRef;

  destroyRef = inject(DestroyRef);
  contactEmail: string = '';
  contactName: string = '';
  address1: string = '';
  address2: string = '';
  cityStateZip: string = '';
  stripeDisclaimer = LegalConstants.STRIPE_DISCLAIMER;
  pageType: string = null;
  pageText: string = null;
  appConfigState: any = null;

  env = environment;

  constructor(
    readonly store: Store<fromApp.AppState>,
    private legalService: LegalService,
    private route: ActivatedRoute
  ) {
    this.pageType = this.route.snapshot.params.pageType;
    this.legalService.getLegalPageContent(this.pageType).subscribe({
      next: legalPageContent => {
        this.pageText = legalPageContent;
        this.updateReplacements();
        this.pageContent.nativeElement.innerHTML = this.pageText;
      },
    });
  }

  ngOnInit() {
    this.store
      .select(selectAppConfigState)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(appConfig => {
        this.appConfigState = appConfig;
        this.updateReplacements();
      });
  }

  updateReplacements() {
    if (this.pageText != null && this.appConfigState != null) {
      const replacements = {
        '%contactEmail%': this.appConfigState.config['fc_contact_email'],
        '%contactName%': this.appConfigState.config['fc_contact_name'],
        '%contactAddress1%': this.appConfigState.config['fc_contact_address_line_1'],
        '%contactAddress2%': this.appConfigState.config['fc_contact_address_line_2'],
        '%contactCity%': this.appConfigState.config['fc_contact_city'],
        '%contactState%': this.appConfigState.config['fc_contact_state'],
        '%contactZip%': this.appConfigState.config['fc_contact_zip'],
        '%stripeDisclaimer%': this.stripeDisclaimer,
      };

      for (const [key, value] of Object.entries(replacements)) {
        this.pageText = this.pageText.replace(key, value);
      }
    }
  }
}
