<div class="container">
  <div class="main">
    @if (!_authMode || _authMode === authModeEnum.login) {
      <fc-auth-login
        [(authMode)]="authMode"
        [(cognitoUser)]="cognitoUser"
        [(email)]="email"
        [(password)]="password"></fc-auth-login>
    } @else if (_authMode === authModeEnum.changePassword) {
      <fc-auth-change-password
        [(authMode)]="authMode"
        [(cognitoUser)]="cognitoUser"
        [(email)]="email"
        [(password)]="password"></fc-auth-change-password>
    } @else if (_authMode === authModeEnum.forgotPassword) {
      <fc-auth-forgot-password
        [(authMode)]="authMode"
        [(cognitoUser)]="cognitoUser"
        [(email)]="email"
        [(password)]="password"></fc-auth-forgot-password>
    } @else if (_authMode === authModeEnum.resetPassword) {
      <fc-auth-reset-password
        [(authMode)]="authMode"
        [(cognitoUser)]="cognitoUser"
        [(email)]="email"
        [(password)]="password"></fc-auth-reset-password>
    }
  </div>
</div>
<fc-spinner [show]="false" name="auth-spinner"></fc-spinner>
