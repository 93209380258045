import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { environment } from '../../../environments/environment';
import { Cohort } from '../../shared/models';
import { deserialize } from '../../shared/models/base-helper';

interface QueryResponse {
  results: any[];
  total: number;
}

interface DeserializedResponse {
  results: Cohort[];
  total: number;
}

@Injectable({
  providedIn: 'root',
})
export class CohortService {
  apiUrl = environment.apiUrl + '/cohorts/';

  constructor(readonly http: HttpClient) {}

  getAllCohorts(): Observable<Cohort[]> {
    return this.getFilteredCohorts('').pipe(map(response => response.results));
  }

  getCohort(id: number, open: boolean): Observable<Cohort> {
    let params = new HttpParams();
    if (open) {
      params = params.append('is_open', 'true');
    }
    return this.http
      .get<any>(`${this.apiUrl}${id}`, { params })
      .pipe(map(cohort => (cohort ? deserialize(cohort, Cohort) : null)));
  }

  listWelcomeDocs(id: number): Observable<any[]> {
    return this.http.get<any[]>(`${this.apiUrl}${id}/welcome-docs`).pipe(map(result => result));
  }

  deleteWelcomeDoc(id: number, name: string): Observable<boolean> {
    return this.http
      .post<boolean>(`${this.apiUrl}${id}/welcome-docs/remove`, { name })
      .pipe(map((result: any) => result['deleted']));
  }

  uploadWelcomeDoc(id: number, file: File): Promise<any> {
    const fr = new FileReader();
    const fileReaderPromise = new Promise<any>(
      resolve =>
        (fr.onload = (): void => {
          if (fr.result) {
            const body = {
              data: fr.result,
              name: file.name,
              content_type: file.type,
            };
            this.http
              .post<any>(`${this.apiUrl}${id}/welcome-docs`, body)
              .pipe(map((response: any): any => response))
              .subscribe((newDoc: any) => {
                resolve(newDoc);
              });
          }
        })
    );
    fr.readAsDataURL(file);
    return fileReaderPromise;
  }

  getFilteredCohorts(filterString: string): Observable<DeserializedResponse> {
    const filterUrl = this.apiUrl + filterString;
    return this.http.get<QueryResponse>(filterUrl).pipe(
      map(response => {
        const deserializedResponse: DeserializedResponse = {
          results: [],
          total: response.total,
        };
        for (const cohort of response.results) {
          deserializedResponse.results.push(deserialize(cohort, Cohort));
        }
        return deserializedResponse;
      })
    );
  }

  getCohortsByLatLong(lat: number = null, lng: number = null): Observable<Cohort[]> {
    let params = new HttpParams();

    if (lat && lng) {
      params = params.append('lat', lat.toString());
      params = params.append('lng', lng.toString());
    }

    return this.http
      .get<any[]>(`${this.apiUrl}lat-long`, { params })
      .pipe(map(cohorts => cohorts.map((cohort: any) => deserialize(cohort, Cohort))));
  }

  getCohortsByCounty(gid: number): Observable<Cohort[]> {
    let params = new HttpParams();
    params = params.append('gid', gid.toString());

    return this.http
      .get<any[]>(`${this.apiUrl}county`, { params })
      .pipe(map(cohorts => cohorts.map((cohort: any) => deserialize(cohort, Cohort))));
  }

  createCohort(cohort: any): Observable<Cohort> {
    return this.http.post<any>(this.apiUrl, cohort).pipe(map((respCohort: any) => deserialize(respCohort, Cohort)));
  }

  updateCohort(cohort: any): Observable<Cohort> {
    return this.http
      .put<any>(`${this.apiUrl}${cohort.id}`, cohort)
      .pipe(map((respCohort: any) => deserialize(respCohort, Cohort)));
  }
}
