import { Location } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, DestroyRef, inject, OnInit } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { Auth } from 'aws-amplify';
import { isEmpty } from 'lodash';
import { take } from 'rxjs/operators';

import { UserService } from '../../../core/api/user.service';
import { RegexConstants } from '../../../shared/constants/regex.constants';
import { SpinnerService } from '../../../shared/spinner/spinner.service';
import * as fromApp from '../../../store';
import { login } from '../../../store/auth/auth.actions';
import { selectEmail } from '../../../store/parcel/parcel.selectors';

@Component({
  selector: 'fc-create-account',
  templateUrl: './create-account.component.html',
  styleUrls: ['./create-account.component.scss'],
})
export class CreateAccountComponent implements OnInit {
  destroyRef = inject(DestroyRef);
  tacNeedsConfirmation = false;
  errorMessage: string = null;
  newUserEmail: string = null;
  newUserPassword: string = null;
  createAccountForm: UntypedFormGroup;

  constructor(
    public dialog: MatDialog,
    readonly spinnerService: SpinnerService,
    readonly store: Store<fromApp.AppState>,
    readonly route: ActivatedRoute,
    readonly router: Router,
    readonly userService: UserService,
    private location: Location,
    private formBuilder: UntypedFormBuilder
  ) {
    this.newUserEmail = this.route.snapshot.queryParamMap.get('username');
    this.newUserPassword = this.route.snapshot.queryParamMap.get('tempPassword');
    this.createForms();
  }

  createForms() {
    this.createAccountForm = this.formBuilder.group({
      tempPassword: [this.newUserPassword, Validators.required],
      password: ['', Validators.required],
      verifyPassword: ['', Validators.required],
      tacClicked: false,
    });
  }

  ngOnInit() {
    this.store
      .select('user', 'error')
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(error => {
        this.errorMessage = error;
        if (error) {
          this.newUserEmail = null;
        }
      });

    this.store
      .select(selectEmail)
      .pipe(take(1), takeUntilDestroyed(this.destroyRef))
      .subscribe(email => {
        if (email) {
          this.createAccountForm.get('email').setValue(email);
        }
      });
  }

  verifyAccount(howDidYouHearAbout: string | null) {
    const { tempPassword, password } = this.createAccountForm.value;
    this.spinnerService.show('app-spinner');
    Auth.signIn(this.newUserEmail, tempPassword)
      .then((cognitoResponse: any) => {
        if (cognitoResponse.challengeName === 'NEW_PASSWORD_REQUIRED') {
          this.spinnerService.show('app-spinner');
          Auth.completeNewPassword(cognitoResponse, password)
            .then(signedUser => {
              this.storeLoginToken(
                signedUser.signInUserSession.idToken.jwtToken,
                signedUser.signInUserSession.idToken.payload.exp,
                howDidYouHearAbout
              );
            })
            .catch(() => {
              this.errorMessage = 'There was an error while changing your password';
            })
            .finally(() => {
              this.spinnerService.hide('app-spinner');
            });
        } else {
          this.storeLoginToken(
            cognitoResponse.signInUserSession.idToken.jwtToken,
            cognitoResponse.signInUserSession.idToken.payload.exp,
            howDidYouHearAbout
          );
        }
      })
      .catch(err => {
        this.errorMessage = err.message;
        console.log('Error SignIn:', err);
      })
      .finally(() => {
        this.spinnerService.hide('app-spinner');
      });
  }

  storeLoginToken(idToken: string, exp: number, howDidYouHearAbout: string): void {
    // if Cognito gave us a token first check to see if the user is valid
    // in our database before we save the token and consider the user logged in
    this.userService.isValid(idToken).subscribe((resp: any) => {
      if (resp instanceof HttpErrorResponse) {
        this.errorMessage = resp.error.detail;
      } else if (resp['status'].toLowerCase() === 'error') {
        this.errorMessage = resp['message'];
      } else if (resp['status'].toLowerCase() === 'ok') {
        this.store.dispatch(login({ data: { idToken, exp } }));
      }
    });
  }

  onSubmit(): void {
    this.errorMessage = null;
    this.tacNeedsConfirmation = false;

    const { password, verifyPassword } = this.createAccountForm.value;
    this.errorMessage = null;
    if (password !== verifyPassword) {
      this.errorMessage = 'Passwords do not match';
    } else if (!password.match(RegexConstants.PASSWORD)) {
      this.errorMessage =
        'Your password is required to be at least 8 characters long and contain at least 1 uppercase letter and 1 number';
    }
    this.tacNeedsConfirmation = !this.createAccountForm.value.tacClicked;
    if (!this.tacNeedsConfirmation && isEmpty(this.errorMessage)) {
      this.verifyAccount(null);
    }
  }

  goBack(): void {
    this.location.back();
  }
}
