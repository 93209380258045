<mat-chip-grid #programsChips aria-label="Programs">
  @for (p of selectedPrograms; track p) {
    <mat-chip-row [disabled]="readonly" (removed)="removeProgram(p)">
      {{ p.name }}
      <button matChipRemove [attr.aria-label]="'Remove ' + p.name">
        <mat-icon>cancel</mat-icon>
      </button>
    </mat-chip-row>
  }
</mat-chip-grid>
@if (!readonly) {
  <mat-form-field class="full-width">
    <mat-label>Select Programs</mat-label>
    <input
      matInput
      #programInput
      data-cy="program-autocomplete-input"
      (keydown)="filterPrograms($event)"
      [matAutocomplete]="auto"
      [matChipInputFor]="programsChips"
      [matChipInputSeparatorKeyCodes]="separatorKeysCodes" />
    <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selectProgram($event)">
      @for (p of filteredPrograms; track p) {
        <mat-option [value]="p" data-cy="program-autocomplete-option">
          {{ p.name }}
        </mat-option>
      }
    </mat-autocomplete>
  </mat-form-field>
}
