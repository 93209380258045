import { Component, DestroyRef, inject, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';

import * as fromApp from '../../../store';
import { Site } from '../../models';
import { ReportResponse } from '../../models/report-response.model';

@Component({
  selector: 'fc-site-detail-reports',
  templateUrl: './site-detail-reports.component.html',
  styleUrls: ['./site-detail-reports.component.scss'],
})
export class SiteDetailReportsComponent implements OnInit {
  destroyRef = inject(DestroyRef);
  displayedColumns: string[] = ['created', 'type', 'view'];
  reports: ReportResponse[] = null;

  @Input() site: Site;
  @Input() isAdmin = false;

  constructor(readonly store: Store<fromApp.AppState>, private router: Router) {}

  ngOnInit() {
    this.reports = this.site?.reportResponses;
  }

  viewReport(element: any) {
    this.router.navigate([`${this.router.url}/report-response/${element.id}`]);
  }
}
