import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, concatMap, map, switchMap, tap } from 'rxjs/operators';

import * as SiteActions from './site.actions';

import { SiteService } from '../../core/api/site.service';
import { FormatHelperService } from '../../core/helper/format-helper.service';
import { Cohort, Stand } from '../../shared/models';
import * as DocumentActions from '../document/document.actions';
import { AppState } from '../index';
import * as ParcelActions from '../parcel/parcel.actions';

@Injectable()
export class SiteEffects {
  constructor(
    readonly actions$: Actions,
    readonly siteService: SiteService,
    public formatHelper: FormatHelperService,
    readonly router: Router,
    readonly store: Store<AppState>
  ) {}

  getMySites$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(SiteActions.getAvailableSites),
      concatMap(action => {
        return this.siteService.getAvailableSites().pipe(
          map(data => SiteActions.getAvailableSitesSuccess({ data })),
          catchError(error => of(SiteActions.getAvailableSitesFailure({ error })))
        );
      })
    );
  });

  getUserSites$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(SiteActions.getUserAvailableSites),
      concatMap(action => {
        return this.siteService.getAvailableSites(action.userId).pipe(
          map(data => SiteActions.getAvailableSitesSuccess({ data })),
          catchError(error => of(SiteActions.getAvailableSitesFailure({ error })))
        );
      })
    );
  });

  setActiveSite$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(SiteActions.setActiveSite),
      concatMap(action => {
        return this.siteService.getSite(action.id).pipe(
          switchMap(data => {
            const parcels = data.versions.slice(-1)[0].parcels;
            const stands = data.versions.slice(-1)[0].stands;

            data.simulatedFinancials.stand_financials.forEach(stand_financial => {
              stands.forEach(stand => {
                if (stand.customId == stand_financial.custom_id) {
                  stand.pricingData = stand_financial;
                  stand.priceValuation = this.formatHelper.formatMinMaxValuation(
                    stand_financial.annual_valuation.map(obj => obj.valuation)
                  );
                }
              });
            });

            const availableCohorts: Cohort[] = data.availableCohorts;
            const actions: any[] = [
              SiteActions.setActiveSiteSuccessWithAvailableCohorts({
                activeSite: data,
                availableCohorts,
              }),
            ];
            actions.push(DocumentActions.getDocuments({ identifier: data.identifier }));
            actions.push(
              ParcelActions.resetParcels({
                data: { parcels, stands, unsavedChanges: false },
              })
            );
            return actions;
          }),
          catchError(error => of(SiteActions.setActiveSiteFailure({ error })))
        );
      })
    );
  });

  setActiveSiteParcelsAndAvailableCohorts$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(SiteActions.setActiveSiteParcelsAndAvailableCohorts),
      concatMap(action => {
        return this.siteService.getSite(action.siteId).pipe(
          switchMap(data => {
            const parcels = action.parcels;
            const availableCohorts = action.availableCohorts;
            const stands: Array<Stand> = data.versions[0].stands;

            data.simulatedFinancials.stand_financials.forEach(stand_financial => {
              stands.forEach(stand => {
                if (stand.customId == stand_financial.custom_id) {
                  stand.pricingData = stand_financial;
                  stand.priceValuation = this.formatHelper.formatMinMaxValuation(
                    stand_financial.annual_valuation.map(obj => obj.valuation)
                  );
                }
              });
            });

            const actions: any[] = [SiteActions.setActiveSiteSuccess({ data })];
            actions.push(DocumentActions.getDocuments({ identifier: data.identifier }));
            actions.push(
              ParcelActions.resetParcels({
                data: { parcels, stands, unsavedChanges: false },
              })
            );
            actions.push(SiteActions.setAvailableCohorts({ cohorts: availableCohorts }));
            return actions;
          }),
          catchError(error => of(SiteActions.setActiveSiteFailure({ error })))
        );
      })
    );
  });

  clearActiveSite$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(SiteActions.clearActiveSite),
      switchMap(action => [ParcelActions.clearAll()])
    );
  });

  createSiteSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(SiteActions.createSiteSuccess),
        tap(action => {
          this.router.navigate([`/site/${action.data.id}/locate-property/select-parcels`]);
        })
      ),
    { dispatch: false }
  );

  updateSelectParcelSiteSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(SiteActions.updateSelectParcelSiteSuccess),
        tap(action => {
          this.router.navigate([`/site/${action.data.id}/map-site`]);
        })
      ),
    { dispatch: false }
  );

  updateSubmitEligibilitySiteSuccessAndNavigate$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(SiteActions.updateSubmitEligibilitySiteSuccessAndNavigate),
        tap(action => {
          this.router.navigate([`/site/${action.data.id}/information-documentation`]);
        })
      ),
    { dispatch: false }
  );

  updateSubmitDocumentationSiteSuccessAndNavigate$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(SiteActions.updateSubmitDocumentationSiteSuccessAndNavigate),
        tap(action => {
          this.router.navigate([`/site/${action.data.id}/submit-site`]);
        })
      ),
    { dispatch: false }
  );

  setSelectedCohortBuyerAndNavigate$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(SiteActions.setSelectedCohortBuyerAndNavigate),
        tap(action => {
          this.router.navigate([`site/${action.data.id}/eligibility-questions`]);
        })
      ),
    { dispatch: false }
  );

  updateSiteStep$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(SiteActions.updateSiteStep),
      concatMap(action => {
        return this.siteService.updateSiteStep(action.id, action.step).pipe(
          switchMap(data => [SiteActions.updateSiteStepSuccess({ data })]),
          catchError(error => of(SiteActions.updateSiteStepFailure({ error })))
        );
      })
    );
  });

  deleteSite$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(SiteActions.deleteSite),
      concatMap(action => {
        return this.siteService.deleteSite(action.id).pipe(
          map(data => SiteActions.deleteSiteSuccess({ data })),
          catchError(error => of(SiteActions.deleteSiteFailure({ error })))
        );
      })
    );
  });

  getSite$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(SiteActions.getSite),
      concatMap(action => {
        return this.siteService.getSite(action.id).pipe(
          map(data => SiteActions.getSiteSuccess({ data })),
          catchError(error => of(SiteActions.getSiteFailure({ error })))
        );
      })
    );
  });

  sendContract$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(SiteActions.sendContract),
      concatMap(action => {
        return this.siteService.sendContract(action.identifier).pipe(
          map(data => SiteActions.sendContractSuccess({ data })),
          catchError(error => of(SiteActions.sendContractFailure({ error })))
        );
      })
    );
  });

  clearAllSitesAndReturnToDash$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(SiteActions.clearAllSitesAndReturnToDash),
        tap(action => {
          const route = '/account/dashboard';
          this.router.navigateByUrl(route, { replaceUrl: true });
        })
      ),
    { dispatch: false }
  );
}
