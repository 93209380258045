import { Action, createReducer, on } from '@ngrx/store';
import { clone } from 'lodash';

import { AppConfigModel } from 'src/app/core/api/app-config.service';

import * as AppConfigActions from './app-config.actions';

export const appConfigFeatureKey = 'appConfig';

type Dictionary = {
  [key: string]: any;
};

export interface AppConfigState {
  config: Dictionary;
  appConfig: Array<AppConfigModel>;
  mobileViewMode: boolean;
  error: string;
}

export const initialState: AppConfigState = {
  config: {
    invite_only: 'true',
    fc_contact_address_line_1: '435 Devon Park Drive',
    fc_contact_address_line_2: '700 Building',
    fc_contact_city: 'Wayne',
    fc_contact_email: 'corecarbon@finitecarbon.com',
    fc_contact_phone: '(445) 465-2098',
    fc_help_email: 'support@finitecarbon.com',
    fc_help_phone: '(445) 465-2098',
    fc_contact_state: 'PA',
    fc_contact_zip: '19087',
  },
  appConfig: [],
  mobileViewMode: false,
  error: null,
};

const appConfigReducer = createReducer(
  initialState,
  on(AppConfigActions.getAppConfigSuccess, (state, data) => {
    const config = {};
    data.data.forEach(element => (config[element.key] = element.value));
    return {
      ...state,
      appConfig: data.data,
      config,
    };
  }),
  on(AppConfigActions.updateAppConfigSuccess, (state, data) => {
    const config = clone(state.config);
    const appConfig = state.appConfig.map(ac => clone(ac));
    config[data.data.key] = data.data.value;
    const idx = appConfig.findIndex(ac => ac.id === data.data.id);
    if (idx > 0) {
      appConfig[idx] = data.data;
    }
    return {
      ...state,
      config,
      appConfig,
    };
  }),
  on(AppConfigActions.updateAppConfigFailure, AppConfigActions.getAppConfigFailure, (state, data) => {
    let error = 'There was an error app config';
    if (data.error?.error?.detail) {
      error = data.error.error.detail;
    } else if (data.error?.message) {
      error = data.error.message;
    }
    return {
      ...state,
      config: initialState.config,
      appConfig: [],
      error,
    };
  }),
  on(AppConfigActions.setMobileViewMode, (state, data) => {
    return {
      ...state,
      mobileViewMode: data.mobileViewMode,
    };
  })
);

export function reducer(state: AppConfigState | undefined, action: Action) {
  return appConfigReducer(state, action);
}
