import { Action, createReducer, on } from '@ngrx/store';
import { cloneDeep } from 'lodash';

import * as DocumentActions from './document.actions';

import { Document, DocumentType } from '../../shared/models';

export const documentFeatureKey = 'document';

export interface DocumentState {
  documentTypes: DocumentType[];
  documents: any;
  error: string | null;
}

export const initialState: DocumentState = {
  documentTypes: null,
  documents: null,
  error: null,
};

const documentReducer = createReducer(
  initialState,
  on(DocumentActions.clearAllDocument, state => ({
    ...state,
    documents: null,
  })),
  on(DocumentActions.getAllDocumentTypes, state => ({
    ...state,
    documentTypes: null,
  })),
  on(DocumentActions.getAllDocumentTypesSuccess, (state, data) => ({
    ...state,
    documentTypes: data.data,
  })),
  on(DocumentActions.deleteDocumentSuccess, (state, data) => {
    const siteId = data.data.site_id;
    const identifier = data.data.document_identifier;
    const documentsCopy = cloneDeep(state.documents[siteId]);

    if (documentsCopy) {
      const newDocuments = documentsCopy.filter((doc: Document) => doc.identifier !== identifier);
      const documents = { [siteId]: newDocuments };

      return {
        ...state,
        documents,
        error: null,
      };
    } else {
      return {
        ...state,
        error: null,
      };
    }
  }),
  on(
    DocumentActions.getDocumentsSuccess,
    DocumentActions.saveDocumentSuccess,
    DocumentActions.createDocumentSuccess,
    (state, data) => {
      const documents = state.documents ? cloneDeep(state.documents) : {};
      if (data.data) {
        const docs: Document[] = Array.isArray(data.data) ? data.data : [data.data];
        docs.forEach((doc: Document) => {
          if (!documents[doc.siteId]) {
            documents[doc.siteId] = [];
          }
          const idx = documents[doc.siteId].findIndex(d => d.id === doc.id);
          if (idx > -1) {
            documents[doc.siteId][idx] = doc;
          } else {
            documents[doc.siteId].push(doc);
          }
        });
      }
      return {
        ...state,
        documents,
      };
    }
  ),
  on(
    DocumentActions.getDocumentsFailure,
    DocumentActions.getAllDocumentTypesFailure,
    DocumentActions.deleteDocumentFailure,
    (state, data) => {
      let error = 'There was an error';
      if (data.error?.error?.detail) {
        error = data.error.error.detail;
      } else if (data.error?.message) {
        error = data.error.message;
      }
      return {
        ...state,
        error,
      };
    }
  )
);

export function reducer(state: DocumentState | undefined, action: Action) {
  return documentReducer(state, action);
}
