import { Component, DestroyRef, inject, OnInit } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { finalize, take } from 'rxjs/operators';

import { UserService } from '../../../core/api/user.service';
import { RedirectService } from '../../../core/helper/redirect.service';
import * as fromApp from '../../../store';
import { setCurrentUser } from '../../../store/auth/auth.actions';
import { selectCurrentUser } from '../../../store/auth/auth.selectors';
import { states } from '../../constants/states.contants';
import { Buyer, User } from '../../models';
import { SpinnerService } from '../../spinner/spinner.service';

@Component({
  selector: 'fc-verify-account',
  templateUrl: './verify-account.component.html',
  styleUrls: ['./verify-account.component.scss'],
})
export class VerifyAccountComponent implements OnInit {
  destroyRef = inject(DestroyRef);
  verifyUserForm: UntypedFormGroup;
  preferredBuyer: Buyer;
  currentUser: User;

  stateList = states;
  dobInvalidMsg = '';

  constructor(
    readonly store: Store<fromApp.AppState>,
    readonly router: Router,
    readonly userService: UserService,
    readonly spinnerService: SpinnerService,
    readonly redirectService: RedirectService,
    private formBuilder: UntypedFormBuilder
  ) {
    this.verifyUserForm = this.formBuilder.group({
      address1: ['', Validators.required],
      address2: '',
      city: ['', Validators.required],
      dateOfBirth: ['', Validators.required],
      firstName: ['', Validators.required],
      last4Ssn: ['', Validators.required],
      lastName: ['', Validators.required],
      licenseNo: '',
      licenseState: '',
      mfaEnabled: [null],
      phone: [null, [Validators.required]],
      state: ['', Validators.required],
      zip: [null, Validators.required],
    });
  }

  ngOnInit() {
    this.store
      .select(selectCurrentUser)
      .pipe(take(1), takeUntilDestroyed(this.destroyRef))
      .subscribe(currentUser => {
        this.currentUser = currentUser;
        this.preferredBuyer = this.currentUser?.preferredBuyer;
      });
  }

  onSubmit() {
    const profile = {
      city: this.verifyUserForm.value.city,
      date_of_birth: new Date(this.verifyUserForm.value.dateOfBirth).getTime() / 1000,
      drivers_license_num: this.verifyUserForm.value.licenseNo,
      drivers_license_state: this.verifyUserForm.value.licenseState,
      first_name: this.verifyUserForm.value.firstName,
      id: this.currentUser.id,
      last_name: this.verifyUserForm.value.lastName,
      mfa_enabled: this.verifyUserForm.value.mfaEnabled,
      phone: this.verifyUserForm.value.phone,
      ssn: this.verifyUserForm.value.last4Ssn,
      state: this.verifyUserForm.value.state,
      street_1: this.verifyUserForm.value.address1,
      street_2: this.verifyUserForm.value.address2,
      zip: this.verifyUserForm.value.zip,
      buyer_id: this.preferredBuyer ? this.preferredBuyer.id : null,
    };
    this.spinnerService.show('app-spinner');
    this.userService
      .updateUserProfile(profile)
      .pipe(finalize(() => this.spinnerService.hide('app-spinner')))
      .subscribe((data: User) => {
        if (data) {
          this.verifyUserForm.markAsPristine();
          this.store.dispatch(setCurrentUser({ data }));
          this.redirectService.redirectToDashboard();
        }
      });
  }

  onCancel() {
    this.redirectService.redirectToDashboard();
  }

  get isFormDirty(): boolean {
    return this.verifyUserForm.dirty;
  }
}
