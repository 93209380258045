import { SelectionModel } from '@angular/cdk/collections';
import { DestroyRef, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { MatDialog } from '@angular/material/dialog';
import { MatSelectChange } from '@angular/material/select';
import { MatTableDataSource } from '@angular/material/table';
import { Store } from '@ngrx/store';
import { isEmpty } from 'lodash';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

import { PaymentSitesResponse } from '../../../core/api/payment.service';
import { UtilitiesService } from '../../../core/api/utlities.service';
import { PaymentPeriodSummary } from '../../../shared/models';
import * as fromApp from '../../../store';
import { clearDashboardFilters, setDashboardFilter, setDashboardPage } from '../../../store/admin/admin.actions';
import { DialogSitePaymentResultsComponent } from '../dialog-site-payment-results/dialog-site-payment-results.component';

export abstract class AdminTableBase {
  destroyRef = inject(DestroyRef);
  readonly FILTER_DELAY = 1000;

  paginationTotal = 40;

  totalItems = 0;
  startIndex = 0;
  endIndex = 0;
  offset = 0;
  showPages = [];
  totalPages = 1;
  currentPage = 1;

  paginationStart = 1;
  paginationEnd = 1;

  filterQuery = '';
  sortQuery = '';
  defaultSortQuery = '';
  statusQuery = '';
  showAssignedToMeQuery = '';
  regionQuery = '';
  assignedAdminQuery = '';

  sortStates = {
    untouched: 'untouched',
    ascending: 'asc',
    descending: 'desc',
  };

  utilitiesService: UtilitiesService;
  abstract store: Store<fromApp.AppState>;
  abstract readonly PAGE_NAME: string;
  dataSource: MatTableDataSource<any>;
  selection = new SelectionModel<any>(true, []);
  enableBulkButtons = false;
  sortBy: any = {};
  filterValues: any = {};

  applyFilter$: Subject<{ event: any; column: string; operand: string }> = new Subject();

  getItemByRegionId$: Subject<{ event: Event; field: string }> = new Subject();
  getItemByAssignedAdminId$: Subject<{ event: Event; field: string }> = new Subject();
  getItemByBuyerId$: Subject<{ event: Event; field: string }> = new Subject();

  initializeFilters(): void {
    this.applyFilter$
      .pipe(debounceTime(this.FILTER_DELAY), takeUntilDestroyed(this.destroyRef))
      .subscribe((eventObj: { event: any; column: string; operand: string }) => {
        this.doApplyFilterSubject(eventObj.event, eventObj.column, eventObj.operand);
      });

    this.getItemByRegionId$
      .pipe(debounceTime(this.FILTER_DELAY), takeUntilDestroyed(this.destroyRef))
      .subscribe((eventObj: { event: Event; field: string }) => {
        this.doGetItemByRegionId(eventObj.event, eventObj.field);
      });
    this.getItemByAssignedAdminId$
      .pipe(debounceTime(this.FILTER_DELAY), takeUntilDestroyed(this.destroyRef))
      .subscribe((eventObj: { event: Event; field: string }) => {
        this.doGetItemByAssignedAdminId(eventObj.event, eventObj.field);
      });
    this.getItemByBuyerId$
      .pipe(debounceTime(this.FILTER_DELAY), takeUntilDestroyed(this.destroyRef))
      .subscribe((eventObj: { event: Event; field: string }) => {
        this.doGetItemByBuyerId(eventObj.event, eventObj.field);
      });
    this.selection.changed.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(() => {
      if (this.selection.isEmpty()) {
        this.enableBulkButtons = false;
      } else {
        this.enableBulkButtons = true;
      }
    });
    this.queryItems();
  }

  applyFilter(event: any, column: string, operand: string = 'contains'): void {
    this.applyFilter$.next({ event, column, operand });
  }

  doGetItemByAssignedAdminId(event: Event | MatSelectChange, field: string): void {
    if (field === 'assigned_admin_id') {
      const filterId = (event as MatSelectChange).value;
      if (filterId) {
        this.assignedAdminQuery = `&${field}=id(eq,${filterId})`;
      } else {
        this.assignedAdminQuery = '';
      }
      this.store.dispatch(
        setDashboardFilter({
          filter: 'assignedAdminQuery',
          value: this.assignedAdminQuery,
          page: this.PAGE_NAME,
        })
      );

      this.resetOffsetAndCurrentPage();
      this.queryItems();
    }
  }

  doGetSiteByStatusField(searchString: any): void {
    if (searchString) {
      // must encode value if we want to support special characters like ; # &
      const filterValue: string = this.utilitiesService.preprocessFilterStrings(searchString);
      this.statusQuery = filterValue.length !== 0 ? `&status=${filterValue}` : '';
    } else {
      this.statusQuery = '';
    }

    this.store.dispatch(
      setDashboardFilter({
        filter: 'statusQuery',
        value: this.statusQuery,
        page: this.PAGE_NAME,
      })
    );

    this.resetOffsetAndCurrentPage();
    this.queryItems();
  }

  doGetMySites(value: string, field: string): void {
    if (!isEmpty(value)) {
      const filterStr = value.length !== 0 ? `&${field}=${value}` : '';
      if (field === 'assigned_to_me') {
        this.showAssignedToMeQuery = filterStr;
      }
    }

    this.store.dispatch(
      setDashboardFilter({
        filter: 'showAssignedToMeQuery',
        value: this.showAssignedToMeQuery,
        page: this.PAGE_NAME,
      })
    );

    this.resetOffsetAndCurrentPage();
    this.queryItems();
  }

  doGetItemByRegionId(event: Event | MatSelectChange, field: string): void {
    if (field === 'program_region' || field === 'region') {
      const filterId = (event as MatSelectChange).value;
      if (filterId) {
        this.regionQuery = `&${field}=id(eq,${filterId})`;
      } else {
        this.regionQuery = '';
      }
      this.store.dispatch(
        setDashboardFilter({
          filter: 'regionQuery',
          value: this.regionQuery,
          page: this.PAGE_NAME,
        })
      );

      this.resetOffsetAndCurrentPage();
      this.queryItems();
    }
  }

  doGetItemByBuyerId(event: Event | MatSelectChange, field: string): void {
    if (field === 'buyer_id' || field === 'report_version') {
      const filterId = (event as MatSelectChange).value;
      if (filterId) {
        this.filterQuery = `&${field}=${filterId}`;
      } else {
        this.filterQuery = '';
      }
      this.store.dispatch(
        setDashboardFilter({
          filter: 'filterQuery',
          value: this.filterQuery,
          page: this.PAGE_NAME,
        })
      );

      this.resetOffsetAndCurrentPage();
      this.queryItems();
    }
  }

  getItemByRegionId(event: Event, field: string): void {
    this.getItemByRegionId$.next({ event, field });
  }

  getItemByAssignedAdminId(event: Event, field: string): void {
    this.getItemByAssignedAdminId$.next({ event, field });
  }

  getItemByBuyerId(event: Event, field: string): void {
    this.getItemByBuyerId$.next({ event, field });
  }

  abstract queryItems();

  clearFilters(): void {
    this.filterQuery = '';
    this.sortQuery = '';
    this.regionQuery = '';
    for (const col in this.filterValues) {
      this.filterValues[col] = '';
    }
    for (const col in this.sortBy) {
      this.sortBy[col] = this.sortStates.untouched;
    }
    this.store.dispatch(clearDashboardFilters({ page: this.PAGE_NAME }));
  }

  getBaseQueryString(): string {
    return `?count=${this.paginationTotal}&offset=${this.offset}`;
  }

  setSort(column: string, state: string) {
    for (const col in this.sortBy) {
      this.sortBy[col] = this.sortStates.untouched;
    }
    this.sortBy[column] = state;
  }

  toggleSingleSort(column: string): void {
    if (this.sortBy[column] === this.sortStates.ascending) {
      this.setSort(column, this.sortStates.descending);
    } else {
      this.setSort(column, this.sortStates.ascending);
    }

    this.setSortQuery();
    this.queryItems();
  }

  toggleMultiSort(column: string): void {
    if (this.sortBy[column] === this.sortStates.ascending) {
      this.sortBy[column] = this.sortStates.descending;
    } else {
      this.sortBy[column] = this.sortStates.ascending;
    }

    this.setSortQuery();
    this.queryItems();
  }

  setSortQuery() {
    const sorts = Object.getOwnPropertyNames(this.sortBy)
      .filter(k => this.sortBy[k] !== this.sortStates.untouched)
      .map(k => `${k}(${this.sortBy[k]})`)
      .join(',');
    this.sortQuery = `&sort=${sorts}`;
    this.store.dispatch(
      setDashboardFilter({
        filter: 'sortQuery',
        value: this.sortQuery,
        page: this.PAGE_NAME,
      })
    );
  }

  setStartEndIndexes(): void {
    this.startIndex = Math.min(this.offset + 1, this.totalItems);
    this.endIndex = Math.min(this.paginationTotal * this.currentPage, this.totalItems);
  }

  setAllPages(): void {
    this.totalPages = Math.ceil(this.totalItems / this.paginationTotal);

    const maxPagesShown = Math.min(10, this.totalPages);

    if (this.totalPages <= maxPagesShown) {
      // total pages less than max so show all pages
      this.paginationStart = 1;
      this.paginationEnd = this.totalPages;
    } else {
      // calculate start and end pages
      const maxPagesBeforeCurrent = Math.floor(maxPagesShown / 2);
      const maxPagesAfterCurrent = Math.ceil(maxPagesShown / 2) - 1;
      if (this.currentPage <= maxPagesBeforeCurrent) {
        this.paginationStart = 1;
        this.paginationEnd = maxPagesShown;
      } else if (this.currentPage + maxPagesAfterCurrent >= this.totalPages) {
        this.paginationStart = this.totalPages - maxPagesShown + 1;
        this.paginationEnd = this.totalPages;
      } else {
        this.paginationStart = this.currentPage - maxPagesBeforeCurrent;
        this.paginationEnd = this.currentPage + maxPagesAfterCurrent;
      }
    }
    this.showPages = Array.from(new Array(maxPagesShown), (x, i) => i + this.paginationStart);
  }

  goToPage(page: number) {
    const pageNum = Number(page);
    if (pageNum >= 1 && pageNum <= this.totalPages) {
      this.offset = (pageNum - 1) * this.paginationTotal;
      this.currentPage = pageNum;
      this.store.dispatch(
        setDashboardPage({
          offset: this.offset,
          currentPage: this.currentPage,
          page: this.PAGE_NAME,
        })
      );
      this.queryItems();
    }
  }

  doApplyFilterSubject(event: any, column: string, operand: string = 'contains'): void {
    const rawFilterValue: string = event.target ? (event.target as HTMLInputElement).value : event.value;

    // must encode value if we want to support special characters like ; # &
    const filterValue: string = rawFilterValue
      ? this.utilitiesService.preprocessFilterString(rawFilterValue)
      : rawFilterValue;

    if (this.filterQuery.length > 0) {
      // remove filter for that attribute if it exists
      const columnIndex = this.filterQuery.indexOf(column);
      if (columnIndex > -1) {
        const endOfFilter = this.filterQuery.indexOf(')', columnIndex);
        this.filterQuery = this.filterQuery.substring(0, columnIndex) + this.filterQuery.substring(endOfFilter + 2);
        // if no other filters exist, empty filterQuery
        const emptyFilterQuery = '&filters=';
        if (this.filterQuery.length === emptyFilterQuery.length) {
          this.filterQuery = '';
        }
      }
    }

    if (filterValue !== undefined && String(filterValue).length > 0) {
      this.filterQuery = this.filterQuery.length === 0 ? '&filters=' : this.filterQuery + ',';
      this.filterQuery += `${column}(${operand},${filterValue})`;
    }
    this.store.dispatch(
      setDashboardFilter({
        filter: 'filterQuery',
        value: this.filterQuery,
        page: this.PAGE_NAME,
      })
    );
    this.resetOffsetAndCurrentPage();
    this.queryItems();
  }

  resetOffsetAndCurrentPage(): void {
    this.offset = 0;
    this.currentPage = 1;
    this.store.dispatch(
      setDashboardPage({
        offset: this.offset,
        currentPage: this.currentPage,
        page: this.PAGE_NAME,
      })
    );
  }

  displayPaymentResults(result: PaymentSitesResponse, dialog: MatDialog): void {
    let paymentText: string;

    if (result.hasError) {
      paymentText = 'There were errors processing the payments.';
    } else {
      paymentText = 'All payments completed successfully.';
    }

    dialog.open(DialogSitePaymentResultsComponent, {
      panelClass: 'fc-site-payments-dialog',
      data: {
        title: 'SITE TRANSACTIONS',
        line1: paymentText,
        sitePayments: result.payments,
        buttonTextYes: 'OK',
      },
    });
  }

  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected(): boolean {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource ? this.dataSource.data.length : 0;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle(): void {
    this.isAllSelected() ? this.selection.clear() : this.dataSource.data.forEach(row => this.selection.select(row));
  }

  /** The label for the checkbox on the passed row */
  checkboxLabel(row?: PaymentPeriodSummary): string {
    if (!row) {
      return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.startDate}`;
  }

  clearSelection(): void {
    this.selection.clear();
    this.enableBulkButtons = false;
  }

  setUpAdminFilter(state: any) {
    let page = state.filters.find(p => p.page === this.PAGE_NAME);
    let pageFilter = null;
    if (page) {
      pageFilter = page.filters;
    }
    // get the initial filtered site list
    this.filterQuery = pageFilter?.filterQuery || '';
    this.statusQuery = pageFilter?.statusQuery || '';
    this.sortQuery = pageFilter?.sortQuery || this.defaultSortQuery;
    this.regionQuery = pageFilter?.regionQuery || '';
    this.assignedAdminQuery = pageFilter?.assignedAdminQuery || '';
    this.showAssignedToMeQuery = pageFilter?.showAssignedToMeQuery || '';
    this.offset = pageFilter ? page.offset : 0;
    this.currentPage = pageFilter ? page.currentPage : 1;

    // set up the filter display values
    this.filterValues.assigned_admin_id =
      this.assignedAdminQuery !== '' ? Number(this.assignedAdminQuery.split(',')[1].replace(')', '')) : null;
    this.filterValues.status =
      this.statusQuery !== '' ? this.statusQuery.substring(8).replace(/%2C/g, ',').replace(/%20/g, ' ').split(',') : [];
    this.filterValues.region = this.regionQuery !== '' ? parseInt(/eq,(.*)\)/.exec(this.regionQuery)[1]) : null;
    this.filterValues.assigned_to_me =
      this.showAssignedToMeQuery !== '' ? this.showAssignedToMeQuery.includes('true') : false;

    if (this.filterQuery !== '') {
      const bits = this.filterQuery.split('=')[1].split(',');

      for (let x = 0; x < bits.length; x = x + 2) {
        const name = bits[x].split('(')[0];
        const value = bits[x + 1].split(')')[0];
        this.filterValues[name] = value;
      }
    }

    // set up the sort indicators
    if (!isEmpty(this.sortQuery)) {
      this.sortQuery
        .substring(6)
        .split(',')
        .filter(s => !isEmpty(s))
        .forEach(sort => {
          const parts = /(.*)\((.*)\)/.exec(sort);
          this.sortBy[parts[1]] = parts[2];
        });
    }
    return pageFilter;
  }

  changeFilterValueToNum(value: string) {
    if (this.filterValues[value] !== null) {
      this.filterValues[value] = Number(this.filterValues[value]);
    }
  }
}
