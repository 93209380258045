import { Action, createReducer, on } from '@ngrx/store';
import { cloneDeep } from 'lodash';

import * as UserActions from './user.actions';

import { User } from '../../shared/models';

export const userFeatureKey = 'user';

export interface UserState {
  users: User[];
  error: string;
}

export const initialState: UserState = {
  users: [],
  error: null,
};

const userReducer = createReducer(
  initialState,
  on(UserActions.clearAllUsers, state => cloneDeep(initialState)),
  on(UserActions.createDelegate, UserActions.removeDelegateSuccess, UserActions.updateUserProfile, state => ({
    ...state,
    error: null,
  })),
  on(UserActions.createUserOpenSuccess, (state, data) => {
    const newState = cloneDeep(state);
    newState.users.push(data.data);
    return newState;
  }),
  on(
    UserActions.createDelegateSuccess,
    UserActions.removeDelegateSuccess,
    UserActions.updateUserProfileSuccess,
    UserActions.verifyUserSuccess,
    UserActions.setUserRoleSuccess,
    (state, data) => {
      const newState = cloneDeep(state);
      if (data.data) {
        if (!newState.users) {
          newState.users = [data.data];
        } else {
          const idx = newState.users.findIndex(u => u.id === data.data.id);
          if (idx > -1) {
            newState.users[idx] = data.data;
          } else {
            newState.users.push(data.data);
          }
        }
      }
      return newState;
    }
  ),
  on(
    UserActions.createUserOpenFailure,
    UserActions.createUserOpenFailure,
    UserActions.removeDelegateFailure,
    UserActions.createDelegateFailure,
    UserActions.updateUserProfileFailure,
    (state, data) => {
      let error = 'There was an error';
      if (data.error?.error?.detail) {
        error = data.error.error.detail;
      } else if (data.error?.message) {
        error = data.error.message;
      }
      return {
        ...state,
        error,
      };
    }
  )
);

export function reducer(state: UserState | undefined, action: Action) {
  return userReducer(state, action);
}
