export const environment = {
  production: true,
  env: 'prod',
  apiUrl: 'https://services.corecarbon.com/api/v1',
  tileServerUrl: 'https://services.corecarbon.com/tile-server',
  openAssetBaseUrl: 'https://assets.corecarbon.com',
  mapbox: {
    accessToken: 'pk.eyJ1IjoiZmMtcHJvZHVjdGlvbiIsImEiOiJja3RpeDd6bTcxNm8xMm5udGY3ODNldzU3In0.cCyYPgvK1v_Bqfb_d4dJQg',
    baseMap: 'mapbox://styles/fc-production/cktitkrvq3of017uifgm2lm64',
    baseMapContour: 'mapbox://styles/fc-production/cktitgqlj3joy17mwzx5r8x8d',
    contourStyleId: 'fc-production/cktitgqlj3joy17mwzx5r8x8d',
    satelliteStyleId: 'fc-production/cktitkrvq3of017uifgm2lm64',
  },
  cognito: {
    clientId: '7m3poohvbp5n35u9per810jagm',
    identityPoolId: 'us-east-1:528fb97e-fbc7-47fa-9730-11671ff53340',
    userPoolId: 'us-east-1_pjaaJAKsP',
  },
  googleAnalyticsID: 'G-Y2NR6X4V7E',
  payment: {
    stripe_connect_url: 'https://connect.stripe.com/express/oauth/authorize',
  },
  site: {
    maxSize: 5000,
    minSize: 40,
  },
};
