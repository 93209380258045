import { Component, Input } from '@angular/core';

@Component({
  selector: 'fc-sort-icon',
  template: `
    <fa-icon [icon]="sortIcon"></fa-icon>
  `,
})
export class FCSortIconComponent {
  @Input() sortBy: any;
  @Input() sortStates: any;
  @Input() column: string;

  get sortIcon() {
    if (this.sortBy[this.column] === this.sortStates.ascending) {
      return ['fas', 'sort-up'];
    } else if (this.sortBy[this.column] === this.sortStates.descending) {
      return ['fas', 'sort-down'];
    } else {
      return ['fal', 'sort'];
    }
  }
}
