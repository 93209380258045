import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { environment } from '../../../environments/environment';
import { deserialize } from '../../shared/models/base-helper';
import { LegalPage } from '../../shared/models/legal-page.model';

interface DeserializedResponse {
  results: LegalPage[];
  total: number;
}
export interface AppConfigModel {
  id: number;
  key: string;
  value: string;
  description: string;
}

@Injectable({
  providedIn: 'root',
})
export class AppConfigService {
  apiUrl = environment.apiUrl + '/app_config/';

  constructor(readonly http: HttpClient) {}

  getAllAppConfig(): Observable<AppConfigModel[]> {
    return this.http.get<AppConfigModel[]>(this.apiUrl);
  }

  getLegalPageContent(pageType: string): Observable<string> {
    return this.http.get<string>(`${this.apiUrl}legal_page/${pageType}/`);
  }

  updateAppConfig(appConfig: AppConfigModel): Observable<AppConfigModel> {
    return this.http.put(`${this.apiUrl}`, appConfig).pipe(map((response: AppConfigModel) => response));
  }

  getAllLegalPages(filterString: string): Observable<DeserializedResponse> {
    const filterUrl = this.apiUrl + 'legal_page/' + filterString;
    return this.http.get<any>(filterUrl).pipe(
      map(response => {
        const deserializedResponse: DeserializedResponse = {
          results: [],
          total: response.total,
        };
        for (const config of response.results) {
          deserializedResponse.results.push(deserialize(config, LegalPage));
        }
        return deserializedResponse;
      })
    );
  }
}
