import {
  Component,
  DestroyRef,
  ElementRef,
  EventEmitter,
  inject,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { FormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
import { cloneDeep } from 'lodash';
import moment from 'moment/moment';
import { mergeMap } from 'rxjs/operators';

import { DocumentService } from '../../../core/api/document.service';
import { SiteService } from '../../../core/api/site.service';
import { FormatHelperService } from '../../../core/helper/format-helper.service';
import { SiteHelperService } from '../../../core/helper/site.service';
import * as fromApp from '../../../store';
import { requiredFalse } from '../../lib/validators';
import { Document, Program, Site, User } from '../../models';
import { ReportConfig } from '../../models/report-config.model';
import { ReportResponse } from '../../models/report-response.model';

@Component({
  selector: 'fc-report-form',
  templateUrl: './report-form.component.html',
  styleUrls: ['./report-form.component.scss'],
})
export class ReportFormComponent implements OnChanges {
  destroyRef = inject(DestroyRef);
  @ViewChild('documentUpload', { static: false }) documentUpload: ElementRef;
  @Input() eligibilityForm: UntypedFormGroup;
  @Input() reportConfig: ReportConfig;
  @Input() activeSite: Site;
  @Input() docFiles: any[] = [];
  @Input() redProgramFlag: boolean = false;
  @Input() currentUser: User;
  @Input() reportResponse: ReportResponse = null;
  @Input() isPreview: boolean = false;
  @Output() selectedProgramsChange = new EventEmitter<Program[]>();
  selectedPrograms: Program[] = [];
  forestDataUpdatedDate: string = '';
  allowedClearing = '0.0';
  showFirewoodQuestion = true;
  annualQuestionsDisabled = false;

  displayReportConfig: ReportConfig = null;
  showPreviouslySelected: boolean = false;

  constructor(
    readonly store: Store<fromApp.AppState>,
    readonly siteService: SiteService,
    public siteHelper: SiteHelperService,
    public formatHelper: FormatHelperService,
    readonly documentService: DocumentService
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (this.reportConfig && (this.activeSite !== null || this.isPreview)) {
      if (this.isPreview) {
        this.eligibilityForm = new UntypedFormGroup({});
      }
      if (this.reportResponse == null && this.activeSite?.submittedDate !== null && !this.isPreview) {
        // pp that apply to this report
        let paymentPeriods = this.activeSite.versions[0].paymentPeriods
          .filter(p => p.reportResponseId === null)
          .sort((a, b) => a.sequence - b.sequence);
        this.annualQuestionsDisabled = this.areAnnualQuestionsDisabled(paymentPeriods[0].sequence);
        let endDate = paymentPeriods[0].endDate;
        paymentPeriods.forEach(pp => {
          if (pp.endDate > endDate && moment().isAfter(pp.endDate)) {
            endDate = pp.endDate;
            this.annualQuestionsDisabled = this.annualQuestionsDisabled && this.areAnnualQuestionsDisabled(pp.sequence);
          }
        });
      }
      if (this.activeSite) {
        this.selectedPrograms = cloneDeep(this.activeSite.programs);
        this.activeSite.otherPrograms?.split(',').forEach((name: string) => {
          this.selectedPrograms.push(new Program({ name: name }));
        });
        this.showFirewoodQuestion = this.activeSite.getFirewoodDeductionRange(this.activeSite.cohortId)[0] !== 0;
        this.forestDataUpdatedDate = moment(this.activeSite.cohort.region.refreshDate, true).format('MMM D, YYYY');
        this.allowedClearing = this.activeSite.allowedClearing.toFixed(1);
      }
      let firewoodIndex = null;
      this.displayReportConfig = cloneDeep(this.reportConfig);
      this.displayReportConfig.config.forEach((question: any, index: number) => {
        if (question.answer_type !== null) {
          let validator = Validators.required;
          if (question.hard_stop) {
            validator = question.expected_answer ? Validators.requiredTrue : requiredFalse;
          }
          this.eligibilityForm.addControl('Custom_question_' + question.sequence, new FormControl(null, validator));
          if (question.allow_comments) {
            this.eligibilityForm.addControl('Custom_question_comments_' + question.sequence, new FormControl(null));
          }
          if (question.is_annual && this.annualQuestionsDisabled) {
            this.eligibilityForm.controls['Custom_question_' + question.sequence].validator = null;
            this.eligibilityForm.controls['Custom_question_' + question.sequence].disable();
            if (question.allow_comments) {
              this.eligibilityForm.controls['Custom_question_comments_' + question.sequence].validator = null;
              this.eligibilityForm.controls['Custom_question_comments_' + question.sequence].disable();
            }
          }
          question.question_text = question.question_text.replace(
            '%forestDataUpdatedDate%',
            this.forestDataUpdatedDate
          );
          question.question_text = question.question_text.replace('%allowedClearing%', this.allowedClearing);

          if (question.special_type == 'firewood') {
            firewoodIndex = index;
          }
          if (question.special_type == 'clearing') {
            if (
              this.activeSite?.hasSmallClearing &&
              this.reportResponse === null &&
              this.activeSite?.submittedDate !== null
            ) {
              this.showPreviouslySelected = true;
              this.eligibilityForm.controls['Custom_question_' + question.sequence].setValue(null);
              this.eligibilityForm.controls['Custom_question_' + question.sequence].disable();
            }
          }
        }
      });
      if (firewoodIndex !== null && !this.showFirewoodQuestion) {
        this.displayReportConfig.config.splice(firewoodIndex, 1);
      }
    }
    if (this.reportResponse !== null && this.reportConfig) {
      this.reportResponse.answers.forEach((answer: any) => {
        this.eligibilityForm.controls['Custom_question_' + answer.sequence].setValue(answer.answer);
        this.eligibilityForm.controls['Custom_question_' + answer.sequence].disable();
        if (answer.comments) {
          this.eligibilityForm.controls['Custom_question_comments_' + answer.sequence].setValue(answer.comments);
          this.eligibilityForm.controls['Custom_question_comments_' + answer.sequence].disable();
        }
      });
    }
  }

  customQuestionSelect($event: any, question: any) {
    // clearing
    if (question.special_type === 'clearing') {
      this.openTreeClearingDialog($event, question);
    }
    if (question.special_type === 'firewood') {
      this.openFirewoodDialog($event, question);
    }
  }

  openFirewoodDialog(event: any, question: any = null): void {
    if (event.value) {
      const dialogRef = this.siteHelper.showFirewoodDialog(
        this.formatHelper.formatMinMaxValuation(this.activeSite.getFirewoodDeductionRange(this.activeSite.cohortId))
      );
      dialogRef.afterClosed().subscribe((agree: boolean) => {
        if (!agree) {
          this.eligibilityForm.controls['Custom_question_' + question.sequence].setValue(false);
          this.eligibilityForm.patchValue({
            firewoodCutting: null,
          });
        }
      });
    }
  }

  areAnnualQuestionsDisabled(periodNo: number): boolean {
    // ask every 12 months
    return periodNo % (12 / this.activeSite.cohort.payPeriodCadence) != 0;
  }
  openTreeClearingDialog(event: any, question: any = null): void {
    if (event.value) {
      const dialogRef = this.siteHelper.showClearingDialog(
        this.allowedClearing,
        this.formatHelper.formatMinMaxValuation(this.activeSite.getClearingDeductionRange(this.activeSite.cohortId))
      );
      dialogRef.afterClosed().subscribe((agree: boolean) => {
        if (!agree) {
          this.eligibilityForm.controls['Custom_question_' + question.sequence].setValue(false);
          this.eligibilityForm.patchValue({
            isSmallClearing: null,
          });
        }
      });
    }
  }

  hasRedProgram(): boolean {
    return this.redProgramFlag && this.selectedPrograms.findIndex(p => p.restrictionLevel === 2) > -1;
  }

  selectDocument() {
    const documentUpload = this.documentUpload.nativeElement;
    documentUpload.onchange = () => {
      if (documentUpload.files.length === 1) {
        const file = documentUpload.files[0];
        if (!file.name.startsWith('.')) {
          let key = null;
          const name = file.name;
          this.documentService
            .uploadFile(this.activeSite.identifier, file)
            .pipe(
              mergeMap(fileKey => {
                key = fileKey;
                const newDoc = new Document({
                  siteId: this.activeSite.id,
                  documentTypeId: this.documentService.getDocTypeId('Leakage'),
                  name: `${file.name}`,
                  identifier: fileKey,
                  userId: this.currentUser.id,
                });
                return this.documentService.createDocument(newDoc);
              })
            )
            .subscribe(() => {
              documentUpload.value = '';
              this.docFiles.push({ name, key });
            });
        }
      }
    };
    documentUpload.click();
  }

  removeDocument(event: any, key: string) {
    const idx = this.docFiles.findIndex(d => d.key === key);
    if (idx !== -1) {
      this.documentService
        .deleteDocument(this.activeSite.identifier, key)
        .subscribe(() => this.docFiles.splice(idx, 1));
    }
  }

  selectProgram(programs: Program[]) {
    this.selectedPrograms = programs;
    this.selectedProgramsChange.emit(programs);
  }
}
