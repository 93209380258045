import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  DestroyRef,
  ElementRef,
  HostListener,
  inject,
  Input,
  OnChanges,
  OnInit,
  SimpleChange,
  ViewChild,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { ThemePalette } from '@angular/material/core';
import { ProgressSpinnerMode } from '@angular/material/progress-spinner';
import { Subject } from 'rxjs';

import { Spinner } from './spinner.enum';
import { SpinnerService } from './spinner.service';

import { fastFadeIn } from '../animations/fade';

@Component({
  selector: 'fc-spinner',
  templateUrl: './spinner.component.html',
  styleUrls: ['./spinner.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [fastFadeIn],
})
export class SpinnerComponent implements OnInit, OnChanges {
  /** Use instructions:

  This is a pared down replica of NgxSpinner, found here: https://github.com/Napster2210/ngx-spinner
  fc-spinner uses MatProgressSpinner, and purposefully provides fewer options as far as animations, colors,
  and styles go.

  Note: Spinners should always be named with the `name` parameter, to avoid potential
  bugs when multiple spinners are present.


  Within just the template:

      <fc-spinner name="spinner-name" [show]="condition"></fc-spinner>


  Programmatically:

      HTML:
        <fc-spinner name="spinner-name"></fc-spinner>

      TS:
        constructor(spinnerService: SpinnerService) {}

        showSpinner() {
          this.spinnerService.show('spinner-name');
        }

        hideSpinner() {
          this.spinnerService.hide('spinner-name');
        }


  Parameters:
    color: Material theme palette {'primary', 'accent', 'warn'}.

    fullScreen: If true, overlays the spinner over the entire page;
      if false, sets spinner within element it is declared within.

    mode: MatProgressSpinner mode {'determinate', 'indeterminate'}.

    name: The name of the spinner, which can be used to access the spinner programmatically.

    overlay: If true, sets a semi-transparent overlay background within the element the spinner in.

    show: Used to set spinner visibility within the template.

    strokeWidth: Stroke width of the spinner (from MatProgressSpinner).

    value: MatProgressSpinner spinner value.

    zIndex: The z-index of the spinner; this may be used to hide spinner behind MatDialogComponents,
      since their z-index is typically 1000 and this component's default is 9999.
  * **/
  @Input() color: ThemePalette = 'primary';
  @Input() fullScreen: boolean = false;
  @Input() mode: ProgressSpinnerMode = 'indeterminate';
  @Input() name: string = 'primary';
  @Input() overlay: boolean = false;
  @Input() show: boolean = true;
  @Input() strokeWidth: number = 10;
  @Input() value: number = 50;
  @Input() zIndex: number = 9999;
  @Input() enhanced?: boolean;
  @Input() text?: string;

  destroyRef = inject(DestroyRef);

  spinner: Spinner = new Spinner();
  ngUnsubscribe: Subject<void> = new Subject();

  @ViewChild('spinnerDOM') spinnerDOM?: { nativeElement: any };

  @HostListener('document:keydown', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent) {
    if (this.spinnerDOM?.nativeElement) {
      if (this.fullScreen || (!this.fullScreen && this.isSpinnerZone(event.target))) {
        event.preventDefault();
      }
    }
  }

  constructor(
    private spinnerService: SpinnerService,
    private changeDetector: ChangeDetectorRef,
    private elementRef: ElementRef
  ) {}

  ngOnInit() {
    this.setDefaultOptions();
    this.initObservable();
  }

  initObservable() {
    this.spinnerService
      .getSpinner(this.name)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((spinner: Spinner) => {
        this.setDefaultOptions();
        Object.assign(this.spinner, spinner);
        this.changeDetector.detectChanges();
      });
  }

  isSpinnerZone(element: any): boolean {
    if (element === this.elementRef.nativeElement.parentElement) {
      return true;
    }
    return element.parentNode && this.isSpinnerZone(element.parentNode);
  }

  setDefaultOptions = () => {
    this.spinner = Spinner.create({
      color: this.color,
      fullScreen: this.fullScreen,
      mode: this.mode,
      name: this.name,
      overlay: this.overlay,
      show: this.show,
      strokeWidth: this.strokeWidth,
      value: this.value,
      zIndex: this.zIndex,
      enhanced: this.enhanced,
      text: this.text,
    });
  };

  ngOnChanges(changes: { [propKey: string]: SimpleChange }) {
    for (const propName in changes) {
      if (propName) {
        const changedProp = changes[propName];
        if (changedProp.isFirstChange()) {
          return;
        } else if (
          typeof changedProp.currentValue !== 'undefined' &&
          changedProp.currentValue !== changedProp.previousValue
        ) {
          if (changedProp.currentValue !== '') {
            // @ts-ignore
            this.spinner[propName] = changedProp.currentValue;
            if (propName === 'name') {
              this.initObservable();
            }
          }
        }
      }
    }
  }
}
