import { Component, DestroyRef, ElementRef, inject, OnInit, ViewChild } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { isEmpty } from 'lodash';
import moment from 'moment';
import { distinctUntilChanged, filter } from 'rxjs/operators';

import { ReportService } from 'src/app/core/api/report.service';

import { DocumentService } from '../../../core/api/document.service';
import { SiteService } from '../../../core/api/site.service';
import { FormatHelperService } from '../../../core/helper/format-helper.service';
import { RedirectService } from '../../../core/helper/redirect.service';
import { SiteHelperService } from '../../../core/helper/site.service';
import * as fromApp from '../../../store';
import { selectCurrentUser } from '../../../store/auth/auth.selectors';
import { setActiveSite, updateSiteFailure } from '../../../store/site/site.actions';
import { selectActiveSite } from '../../../store/site/site.selectors';
import { LegalConstants } from '../../constants/legal.constants';
import { ReportType } from '../../constants/report_types.constants';
import { Site, User } from '../../models';
import { ReportConfig } from '../../models/report-config.model';
import { ReportResponse } from '../../models/report-response.model';
import { SpinnerService } from '../../spinner/spinner.service';

@Component({
  selector: 'fc-report-response',
  templateUrl: './report-response.component.html',
  styleUrls: ['./report-response.component.scss'],
})
export class ReportResponseComponent implements OnInit {
  @ViewChild('documentUpload', { static: false })
  documentUpload: ElementRef;

  destroyRef = inject(DestroyRef);

  protected readonly LegalConstants = LegalConstants;
  showPreviousOption: boolean = false;
  id: number = null;
  reportId: number = null;
  currentUser: User = null;
  site: Site = null;
  reportForm: UntypedFormGroup;
  isAdmin: boolean = false;
  parcelCommaSeparatedList: string = null;
  statuses = [];
  firewoodQuestionDisabled: boolean = false;
  clearingQuestionDisabled: boolean = false;
  firewoodValuePerYearAdjustment = null;
  allowedClearing = '0.0';
  docFiles: any[] = [];
  leakageFileURL: string = null;
  beginningDate: Date = null;
  endDate: Date = null;
  submittedBy: User = null;
  report: ReportResponse = null;
  reportConfig: ReportConfig = null;
  reportType: string = null;

  constructor(
    public dialog: MatDialog,
    readonly store: Store<fromApp.AppState>,
    readonly router: Router,
    readonly siteHelper: SiteHelperService,
    readonly formatHelper: FormatHelperService,
    readonly documentService: DocumentService,
    readonly redirectService: RedirectService,
    private route: ActivatedRoute,
    private formBuilder: UntypedFormBuilder,
    readonly spinnerService: SpinnerService,
    readonly reportService: ReportService,
    readonly siteService: SiteService
  ) {
    this.createForm();
  }

  ngOnInit() {
    this.statuses = this.siteHelper.siteStatusList;

    this.id = +this.route.snapshot.params.id;
    this.store.dispatch(setActiveSite({ id: this.id }));
    if (this.route.snapshot.params.reportId) {
      this.reportId = +this.route.snapshot.params.reportId;
    }
    this.reportType = this.route.snapshot.queryParamMap.get('reportType') || ReportType.Periodic;

    this.store
      .select(selectActiveSite)
      .pipe(
        filter(site => !isEmpty(site) && site.id === this.id),
        distinctUntilChanged((a, b) => JSON.stringify(a) === JSON.stringify(b)),
        takeUntilDestroyed(this.destroyRef)
      )
      .subscribe(activeSite => {
        this.site = activeSite;
        if (this.reportId) {
          this.report = this.site.reportResponses.find(report => report.id === this.reportId);
          this.reportConfig = this.report.reportConfig;
          this.reportType = this.report.reportType;
        } else {
          this.reportService.getReportConfig(this.site.cohort.methodology, this.reportType).subscribe(response => {
            this.reportConfig = response;
          });
        }
        if (this.reportType == ReportType.Periodic) {
          let paymentPeriods = this.site.versions[0].paymentPeriods
            .filter(p => p.reportResponseId === this.reportId)
            .sort((a, b) => a.sequence - b.sequence);
          this.beginningDate = paymentPeriods[0]?.sequence > 0 ? paymentPeriods[0].startDate : this.site.submittedDate;
          this.endDate = paymentPeriods[0]?.endDate;
          paymentPeriods.forEach(pp => {
            if (pp.endDate > this.endDate && moment().isAfter(pp.endDate)) {
              this.endDate = pp.endDate;
            }
          });
        }
        this.allowedClearing = this.site.allowedClearing.toFixed(1);
      });

    this.store
      .select(selectCurrentUser)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(currentUser => {
        this.currentUser = currentUser;
        this.isAdmin = currentUser.isAdmin;
      });
  }

  createForm(): void {
    this.reportForm = this.formBuilder.group({});
  }

  submitReport(): void {
    if (this.reportForm.invalid) {
      window.scrollTo(0, 0);
    } else {
      let response = new ReportResponse();
      response.siteId = this.site.id;
      response.reportConfigId = this.reportConfig.id;
      response.reportType = this.reportConfig.reportType;
      response.answers = [];
      this.reportConfig.config.forEach((question: any) => {
        if (question.sequence >= 0) {
          let answer = this.reportForm.controls['Custom_question_' + question.sequence]?.value;
          let comments = this.reportForm.controls['Custom_question_comments_' + question.sequence]?.value;
          response.answers.push({
            sequence: question.sequence,
            special_type: question.special_type,
            answer: answer,
            comments: comments,
          });
        }
      });
      this.reportService
        .submitReportResponse(this.site.id, response)
        .pipe(takeUntilDestroyed(this.destroyRef))
        .subscribe(
          reportResponse => {
            this.reportForm.markAsPristine();
            this.redirectService.redirectToDashboard();
          },
          error => this.store.dispatch(updateSiteFailure({ error }))
        );
    }
  }

  get readOnly(): boolean {
    return this.isAdmin || this.reportId != null;
  }

  get isFormDirty(): boolean {
    return this.reportForm.dirty;
  }

  viewOriginal() {
    let reportTypeUrl =
      this.reportType === ReportType.Eligibility ? ReportType.Periodic.toLowerCase() : this.reportType.toLowerCase();
    this.router.navigate([`admin/site/${this.report.siteId}/${reportTypeUrl}-report/${this.report.originalReportId}`]);
  }

  getReportTypeUrl() {
    return this.reportType === ReportType.Eligibility
      ? ReportType.Periodic.toLowerCase()
      : this.reportType.toLowerCase();
  }
}
