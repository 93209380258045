import { Action, createReducer, on } from '@ngrx/store';
import { cloneDeep } from 'lodash';

import * as SiteNoteActions from './site-note.actions';

import { SiteNote } from '../../shared/models';

export const siteNoteFeatureKey = 'siteNote';

export interface SiteNoteState {
  siteNotes: SiteNote[];
}

export const initialState: SiteNoteState = {
  siteNotes: null,
};

const siteNoteReducer = createReducer(
  initialState,
  on(SiteNoteActions.getSiteNotes, state => ({
    ...state,
    siteNotes: null,
  })),
  on(SiteNoteActions.getSiteNotesSuccess, (state, data) => {
    return {
      ...state,
      siteNotes: data.data,
    };
  }),
  on(SiteNoteActions.createSiteNoteSuccess, (state, data) => {
    const siteNotes = cloneDeep(state.siteNotes);
    siteNotes.push(data.data);
    return {
      ...state,
      error: null,
      siteNotes,
    };
  }),
  on(SiteNoteActions.getSiteNotesFailure, SiteNoteActions.createSiteNoteFailure, (state, data) => {
    let error = 'There was an error';
    if (data.error?.error?.detail) {
      error = data.error.error.detail;
    } else if (data.error?.message) {
      error = data.error.message;
    }
    return {
      ...state,
      error,
    };
  })
);

export function reducer(state: SiteNoteState | undefined, action: Action) {
  return siteNoteReducer(state, action);
}
