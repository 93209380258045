import { HttpClient, HttpParams } from '@angular/common/http';
import { DestroyRef, inject, Injectable } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { MatDialog } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { forkJoin, Observable } from 'rxjs';
import { finalize, map, switchMap, take } from 'rxjs/operators';

import { CSVResponse } from './premium-acreage-user.service';

import { environment } from '../../../environments/environment';
import { StatusEnum } from '../../shared/constants/site_status.enum';
import {
  Parcel,
  PaymentPeriod,
  PaymentPeriodDetail,
  PaymentPeriodSummary,
  Program,
  Site,
  SiteReviewNote,
  Stand,
} from '../../shared/models';
import { deserialize, serialize } from '../../shared/models/base-helper';
import { SiteStatus } from '../../shared/models/site-status';
import { SiteReviewCheck } from '../../shared/models/site_review_check.model';
import { SpinnerService } from '../../shared/spinner/spinner.service';
import * as fromApp from '../../store';
import { selectActAsUser } from '../../store/auth/auth.selectors';
import { selectAccountParcelsStandsProgramsAndRegion } from '../../store/parcel/parcel.selectors';
import { selectAvailableCohorts } from '../../store/site/site.selectors';

interface QueryResponse {
  results: any[];
  total: number;
}

interface DeserializedResponse {
  results: Site[];
  total: number;
}

interface PaymentPeriodQueryResponse {
  results: any[];
  total: number;
}

interface DeserializedPaymentPeriodResponse {
  results: PaymentPeriodSummary[];
  total: number;
}

interface ChangeSitesStateRequest {
  site_ids: Array<number>;
  transition_from_state: string;
  transition_to_state: string;
}

interface ChangeSitesStateResponse {
  siteIds: Array<number>;
  error: string;
}

interface FileDownloadResponse {
  bytes: string;
}

export interface AvailableCohortData {
  cohort_id: number;
  initial_fixed_years: number;
  internal_buffer_value: number;
  minimum_ert_per_acre: number;
  minimum_payment_per_acre: number;
  market_price_percentage: number;
  price_per_ton: number;
}

@Injectable({
  providedIn: 'root',
})
export class SiteService {
  apiUrl = environment.apiUrl + '/sites/';

  destroyRef = inject(DestroyRef);

  constructor(
    public dialog: MatDialog,
    readonly http: HttpClient,
    readonly spinnerService: SpinnerService,
    readonly store: Store<fromApp.AppState>
  ) {}

  updateSiteStep(id: number, step: string): Observable<boolean> {
    const body = { site_id: id, step: step };
    return this.http.post<boolean>(`${this.apiUrl}updateStep`, body).pipe(
      map((response: any) => {
        return true;
      })
    );
  }

  setAssignedAdmin(siteId: number, adminId: number): Observable<Site> {
    return this.http
      .patch<any>(`${this.apiUrl}${siteId}/assigned_admin`, {
        admin_id: adminId,
      })
      .pipe(map(serializedSite => deserialize(serializedSite, Site)));
  }

  getSite(id: number): Observable<Site> {
    return this.http.get<any>(`${this.apiUrl}${id}`).pipe(map(serializedSite => deserialize(serializedSite, Site)));
  }

  getAvailableSites(userId?: number): Observable<Site[]> {
    let params = new HttpParams();
    if (userId) {
      params = params.append('user_id', userId.toString());
    }
    return this.http
      .get<any[]>(`${this.apiUrl}my`, { params })
      .pipe(
        map(sites => sites.map((serializedSite: any) => deserialize(serializedSite, Site)).sort((a, b) => a.id - b.id))
      );
  }

  getSitesForStatDashboard(): Observable<DeserializedResponse> {
    return this.http.get<QueryResponse>(`${this.apiUrl}stat_dashboard/`).pipe(
      map(response => {
        const deserializedResponse: DeserializedResponse = {
          results: [],
          total: response.total,
        };
        for (const serializedSite of response.results) {
          deserializedResponse.results.push(deserialize(serializedSite, Site));
        }
        return deserializedResponse;
      })
    );
  }

  getFilteredSites(filterString: string): Observable<DeserializedResponse> {
    const filterUrl = this.apiUrl + filterString;

    return this.http.get<QueryResponse>(filterUrl).pipe(
      map(response => {
        const deserializedResponse: DeserializedResponse = {
          results: [],
          total: response.total,
        };
        for (const serializedSite of response.results) {
          deserializedResponse.results.push(deserialize(serializedSite, Site));
        }
        return deserializedResponse;
      })
    );
  }

  changeSitesState(
    siteIds: Array<number>,
    transitionFromState: string,
    transitionToState: string
  ): Observable<ChangeSitesStateResponse> {
    const payload: ChangeSitesStateRequest = {
      site_ids: siteIds,
      transition_from_state: transitionFromState,
      transition_to_state: transitionToState,
    };

    return this.http.post<ChangeSitesStateResponse>(`${this.apiUrl}change_state`, payload).pipe(
      map((response: any) => {
        return {
          siteIds: response.site_ids,
          error: response.error,
        };
      })
    );
  }

  resetAvailableCohorts(id: number): Observable<Site> {
    this.spinnerService.show('app-spinner', true, 'Updating Site');
    return this.http.patch<any>(`${this.apiUrl}${id}/reset_available_cohorts`, null).pipe(
      map((serializedSite: any) => deserialize(serializedSite, Site)),
      finalize(() => this.spinnerService.hide('app-spinner'))
    );
  }

  updateSite(id: number, site: any | Site, report?: any): Observable<Site> {
    this.spinnerService.show('app-spinner', true, 'Updating Site');
    return this.http.put<any>(`${this.apiUrl}${id}`, { site, report }).pipe(
      map((serializedSite: any) => deserialize(serializedSite, Site)),
      finalize(() => this.spinnerService.hide('app-spinner'))
    );
  }

  updateSiteParcels(id: number, parcels: Array<Parcel>): Observable<Site> {
    this.spinnerService.show('app-spinner', true, 'Updating Parcels');
    return this.http.patch<any>(`${this.apiUrl}${id}/parcels`, parcels).pipe(
      map((serializedSite: any) => deserialize(serializedSite, Site)),
      finalize(() => this.spinnerService.hide('app-spinner'))
    );
  }

  updateSiteStands(id: number, stands: Array<Stand>): Observable<Site> {
    this.spinnerService.show('app-spinner', true, 'Updating Site');
    return this.http.patch<any>(`${this.apiUrl}${id}/stands`, stands).pipe(
      map((serializedSite: any) => deserialize(serializedSite, Site)),
      finalize(() => this.spinnerService.hide('app-spinner'))
    );
  }

  updateSiteName(id: number, name: string): Observable<Site> {
    this.spinnerService.show('app-spinner', true, 'Updating Site');
    return this.http
      .patch<any>(`${this.apiUrl}${id}/name`, null, {
        params: new HttpParams().set('name', name),
      })
      .pipe(
        map((serializedSite: any) => deserialize(serializedSite, Site)),
        finalize(() => this.spinnerService.hide('app-spinner'))
      );
  }

  updateSiteMetadata(id: number, site_in: any): Observable<Site> {
    this.spinnerService.show('app-spinner', true, 'Updating Site');
    return this.http.patch<any>(`${this.apiUrl}${id}/metadata`, site_in).pipe(
      map((serializedSite: any) => deserialize(serializedSite, Site)),
      finalize(() => this.spinnerService.hide('app-spinner'))
    );
  }

  updateInitialAdjustment(id: number, adjustment: number): Observable<Site> {
    this.spinnerService.show('app-spinner', true, 'Updating Site');
    return this.http
      .patch<any>(`${this.apiUrl}${id}/initial_adjustment`, null, {
        params: new HttpParams().set('adjustment', adjustment),
      })
      .pipe(
        map((serializedSite: any) => deserialize(serializedSite, Site)),
        finalize(() => this.spinnerService.hide('app-spinner'))
      );
  }

  updateCohort(id: number, cohort_id: number): Observable<Site> {
    this.spinnerService.show('app-spinner', true, 'Updating Site');
    return this.http
      .patch<any>(`${this.apiUrl}${id}/cohort`, null, {
        params: new HttpParams().set('cohort_id', cohort_id),
      })
      .pipe(
        map((serializedSite: any) => deserialize(serializedSite, Site)),
        finalize(() => this.spinnerService.hide('app-spinner'))
      );
  }

  updateSiteEligibilityReport(
    id: number,
    programs: Program[] = [],
    other_programs: string = null,
    report: any = null
  ): Observable<Site> {
    this.spinnerService.show('app-spinner', true, 'Updating Site');
    return this.http
      .patch<any>(`${this.apiUrl}${id}/eligibility_report_programs`, {
        programs,
        other_programs,
        report,
      })
      .pipe(
        map((serializedSite: any) => deserialize(serializedSite, Site)),
        finalize(() => this.spinnerService.hide('app-spinner'))
      );
  }

  deleteSite(id: number): Observable<Site> {
    return this.http
      .delete<any>(`${this.apiUrl}${id}`)
      .pipe(map((serializedSite: any) => deserialize(serializedSite, Site)));
  }

  submitSite(id: number, cohortId: number): Observable<Site> {
    return this.http
      .put<any>(`${this.apiUrl}${id}/submit`, {
        cohort_id: cohortId,
      })
      .pipe(map((serializedSite: any) => deserialize(serializedSite, Site)));
  }

  restartSite(id: number): Observable<boolean> {
    return this.http.get<any>(`${this.apiUrl}${id}/restart`).pipe(map(resp => resp.restarted));
  }

  siteNeedsRestart(id: number): Observable<boolean> {
    return this.http.get<any>(`${this.apiUrl}${id}/needs_restart`).pipe(map(resp => resp.needs_restart));
  }

  sitePublicOverlap(id: number): Observable<number> {
    return this.http.get<any>(`${this.apiUrl}${id}/get_public_overlap`);
  }

  updateSiteParcelGIDs(id: number): Observable<Site> {
    return this.http
      .get<any>(`${this.apiUrl}${id}/update_parcel_gids`)
      .pipe(map((serializedSite: any) => deserialize(serializedSite, Site)));
  }

  sendContract(siteId: string): Observable<Site> {
    return this.http
      .put<any>(`${this.apiUrl}send_contract?site_identifier=${siteId}`, {})
      .pipe(map((serializedSite: any) => deserialize(serializedSite, Site)));
  }

  downloadChecklist(siteId: number): Observable<string> {
    return this.http
      .get(`${this.apiUrl}${siteId}/download_checklist`)
      .pipe(map((response: FileDownloadResponse) => response.bytes));
  }

  downloadContract(siteId: string): Observable<string> {
    return this.http
      .put(`${this.apiUrl}download_contract?site_identifier=${siteId}`, {})
      .pipe(map((response: FileDownloadResponse) => response.bytes));
  }

  updateSiteOwner(siteId: number, userId: number): Observable<Site> {
    return this.http
      .patch<any>(`${this.apiUrl}${siteId}/owner`, {
        user_id: userId,
      })
      .pipe(map((serializedSite: any) => deserialize(serializedSite, Site)));
  }

  getAvailableCohortData(siteId: number): Observable<AvailableCohortData[]> {
    return this.http.get<AvailableCohortData[]>(`${this.apiUrl}${siteId}/available_cohorts`).pipe(map(resp => resp));
  }

  createSite(): Observable<Site> {
    this.spinnerService.show('app-spinner');
    return forkJoin([
      this.store.select(selectAccountParcelsStandsProgramsAndRegion).pipe(take(1)),
      this.store.select(selectActAsUser).pipe(take(1)),
      this.store.select(selectAvailableCohorts).pipe(take(1)),
    ]).pipe(
      switchMap(([accountActiveSiteParcelRegionStands, actAsUser, cohorts]) => {
        const multiState = accountActiveSiteParcelRegionStands;
        const account = accountActiveSiteParcelRegionStands.account;
        const accountId = account ? account.id : null;
        const mapCenter = multiState.mapCenter;
        const regionId = multiState.region ? multiState.region.id : null;

        this.spinnerService.show('app-spinner');
        return this.http
          .post<any>(`${this.apiUrl}`, {
            account_id: accountId,
            region_id: regionId,
            name: `New Site - ${new Date().toLocaleString()}`,
            initial_lat: mapCenter.lat,
            initial_lng: mapCenter.lng,
          })
          .pipe(
            map((serializedSite: any) => deserialize(serializedSite, Site)),
            finalize(() => this.spinnerService.hide('app-spinner'))
          );
      }),
      finalize(() => this.spinnerService.hide('app-spinner')),
      takeUntilDestroyed(this.destroyRef)
    );
  }

  getFilteredPaymentPeriods(
    filterString: string,
    includeAllSubmitted: boolean = false
  ): Observable<DeserializedPaymentPeriodResponse> {
    const filterUrl = this.apiUrl + 'paymentPeriods/summary/' + filterString + '&include_all=' + includeAllSubmitted;
    return this.http.get<PaymentPeriodQueryResponse>(filterUrl).pipe(
      map(response => {
        const deserializedResponse: DeserializedPaymentPeriodResponse = {
          results: response.results.map(serializedPaymentPeriodSummary =>
            deserialize(serializedPaymentPeriodSummary, PaymentPeriodSummary)
          ),
          total: response.total,
        };
        return deserializedResponse;
      })
    );
  }

  getDetailedPaymentPeriod(
    periods: Array<PaymentPeriodSummary>,
    includeAllSubmitted: boolean = false
  ): Observable<PaymentPeriodDetail[]> {
    let serializedPeriods = periods.map(period => serialize(period));
    const params = new HttpParams().set('include_all', includeAllSubmitted);
    return this.http
      .post<any[]>(this.apiUrl + 'paymentPeriods/detail/', serializedPeriods, {
        params,
      })
      .pipe(
        map(response =>
          response.map(serializedPaymentPeriodDetail => deserialize(serializedPaymentPeriodDetail, PaymentPeriodDetail))
        )
      );
  }

  sendNotification(siteId: number, note: string, newStatus: string): Observable<Site> {
    return this.http
      .post<any>(`${this.apiUrl}${siteId}/notify`, {
        note,
        type: newStatus == StatusEnum.REQUIRES_LANDOWNER_REVISION ? 'revisions' : 'documentation',
      })
      .pipe(map((serializedSite: any) => deserialize(serializedSite, Site)));
  }

  saveSiteReviewNote(siteReviewNote: SiteReviewNote): Observable<Site> {
    if (siteReviewNote.id) {
      return this.http
        .put<any>(`${this.apiUrl}site_review_note/${siteReviewNote.id}`, serialize(siteReviewNote))
        .pipe(map((serializedSite: any) => deserialize(serializedSite, Site)));
    } else {
      return this.http
        .post<any>(`${this.apiUrl}site_review_note`, serialize(siteReviewNote))
        .pipe(map((serializedSite: any) => deserialize(serializedSite, Site)));
    }
  }

  saveSiteReviewCheck(siteReviewCheck: SiteReviewCheck): Observable<Site> {
    if (siteReviewCheck.id) {
      return this.http
        .put<any>(`${this.apiUrl}site_review_check/${siteReviewCheck.id}`, serialize(siteReviewCheck))
        .pipe(map((serializedSite: any) => deserialize(serializedSite, Site)));
    } else {
      return this.http
        .post<any>(`${this.apiUrl}site_review_check`, serialize(siteReviewCheck))
        .pipe(map((serializedSite: any) => deserialize(serializedSite, Site)));
    }
  }

  splitSite(siteId: number, effectiveDate: Date, newKml: any) {
    return this.http
      .post<any>(`${this.apiUrl}${siteId}/split_site`, {
        effective_date: effectiveDate,
        kml: newKml,
      })
      .pipe(
        map((response: any) => {
          return response.results;
        })
      );
  }

  updateParcelData(siteId, id, owner, address, parcelNumber, county, state) {
    return this.http
      .patch<any>(`${this.apiUrl}${siteId}/parcels/`, {
        id: id,
        owner: owner,
        parcelNumber: parcelNumber,
        address: address,
        county: county,
        state: state,
      })
      .pipe(
        map((response: any) => {
          return response.results;
        })
      );
  }

  updatePaymentPeriod(siteId: number, updatePaymentPeriod: any): Observable<PaymentPeriod> {
    return this.http
      .patch<any>(
        `${this.apiUrl}${siteId}/paymentPeriods/${updatePaymentPeriod.payment_period.id}/`,
        updatePaymentPeriod
      )
      .pipe(
        map((serializedPaymentPeriod: any) => {
          return deserialize(serializedPaymentPeriod, PaymentPeriod);
        })
      );
  }

  getOwnershipChangeCSV() {
    return this.http
      .get<CSVResponse>(`${this.apiUrl}ownership_change/csv`)
      .pipe(map((response: CSVResponse) => response.csv_string));
  }

  getChangeDetectionCSV(threshold: any) {
    return this.http
      .get<CSVResponse>(`${this.apiUrl}change_detection/csv?threshold=${threshold}`)
      .pipe(map((response: CSVResponse) => response.csv_string));
  }

  getAllSiteStatuses(): Observable<SiteStatus[]> {
    return this.http.get<SiteStatus[]>(`${this.apiUrl}site_statuses/`);
  }
}
