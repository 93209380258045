import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Store } from '@ngrx/store';

import { GisService } from '../../../core/api/gis.service';
import { SiteService } from '../../../core/api/site.service';
import * as fromApp from '../../../store';
import { setActiveSiteSuccessWithAvailableCohorts } from '../../../store/site/site.actions';

@Component({
  selector: 'fc-dialog-recalculate-site',
  templateUrl: './dialog-recalculate-site.component.html',
  styleUrls: ['./dialog-recalculate-site.component.scss'],
})
export class RecalculateSiteDialogComponent {
  site: any;

  constructor(
    public dialogRef: MatDialogRef<RecalculateSiteDialogComponent>,
    readonly store: Store<fromApp.AppState>,
    readonly siteService: SiteService,
    readonly gisService: GisService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.site = data.site;
  }

  onProceedClick() {
    this.siteService.updateSiteStands(this.site.id, this.site.versions[0].stands).subscribe(updated_proj => {
      this.store.dispatch(
        setActiveSiteSuccessWithAvailableCohorts({
          activeSite: updated_proj,
          availableCohorts: updated_proj.availableCohorts,
        })
      );
      this.dialogRef.close(true);
    });
  }
}
